export default {
    getCardDetail: 'miniProgram/getCardDetail',
    getProductCategoryList: 'product/getProductCategoryList', // 商品分类
    getProductList: 'product/getProductList', // 商品列表
    getProductDetail: 'product/getProductDetail', // 商品详情
    getUserAdressList: 'product/getUserAdressList', // 获取收货地址
    exchangeProduct: 'product/exchangeProduct', // 兑换商品
    addUserAdress: 'product/addUserAdress', // 添加地址
    getUserAdress: 'product/getUserAdress', // 获取地址
    getOrderList: 'product/getOrderList', // 订单列表
    getSuperiorRecords: 'miniProgram/getSuperiorRecords', // 获取推荐记录
    updateUserAdress: 'product/updateUserAdress', // 修改地址
    isBrushPermission: 'firstbrush/isBrushPermission', // 是否有手刷权限
    brushPermissionRegister: 'firstbrush/brushPermissionRegister', // 首刷礼注册
    useCouponCode: 'firstbrush/useCouponCode', // 使用首刷礼卷
    exchangeFirstbrush: 'product/exchangeFirstbrush', // 兑换普通首刷礼
    receiveOrder: 'product/receiveOrder', // 确认订单
    isHasBrushPermission: 'firstbrush/isHasBrushPermission', // 获取普通首刷礼次数
    getUserCouponList: 'coupon/getUserCouponList', // 优惠卷
    exchangeSysCoupon: 'product/exchangeSysCoupon', // 兑换首刷优惠卷
    getCounponCode: 'coupon/getCounponCode', // 兑换二维码
    consumeCoupon: 'coupon/consumeCoupon', // 使用二维码
    userAgree: 'wechat/userAgree', // 同意申请卡片
    refreshUserData: 'wechat/refreshUserData', // 刷新用户信息
    grantFirstbrush: 'firstbrush/grantFirstbrush', // 同意申请卡片
    getUserTaxRatio: 'miniProgram/getUserTaxRatio', // 获取税率
    getUsercouponRecordList: 'couponRecord/getUsercouponRecordList', // 首刷礼申请记录
    applicationCounponRecord: 'couponRecord/applicationCounponRecord' //首刷礼申请
};
