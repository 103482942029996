export default {
    getCarousels: 'miniProgram/getCarousels', // 获取轮播图信息
    getCardList: 'miniProgram/getCardList', // 获取卡片信息
    shagePartnerBill: 'miniProgram/shagePartnerBill', // 合伙人海报
    shageProgramBill: 'miniProgram/shageProgramBill', // 分享赚佣金海报
    getOrderType: 'product/getOrderType', // 微信立减金类型
    upgradePartner: 'miniProgram/upgradePartner', // 获取卡片信息
    getRewardLogs: 'miniProgram/getRewardLogs', // 获取收益明细
    getUserAccount: 'miniProgram/getUserAccount', // 个人积分
    uploadIdCard: 'cashOut/upload/idCard', // 图片上次
    userSign: 'cashOut/userSign', // 用户签约
    addUserAccount: 'cashOut/addUserAccount', // 添加银行卡
    getUserSignInfor: 'cashOut/getUserSignInfor', // 获取用户签约信息
    queryUserAccountList: 'cashOut/queryUserAccountList', // 获取银行卡列表
    queryCashOutList: 'cashOut/queryCashOutList', // 提现列表
    userDrawCash: 'cashOut/userDrawCash', // 提现
    queryUserAccount: 'cashOut/queryUserAccount', // 查询账号详情
    getUserTaxRatio: 'miniProgram/getUserTaxRatio', // 获取税率
    removeUserAccount: 'cashOut/removeUserAccount', // 删除账号信息
    getWechatConfig: 'miniProgram/getWechatConfig', // 获取签名
    getChannelInformation: 'miniProgram/getChannelInformation', // 获取渠道信息
    userLogin: 'miniProgram/login' // 登录
};
