// key  value 形式数组转obj
export const arrToObj = (arr) => {
  let trueObj = {};
  for (let i = 0; i < arr.length; i++) {
    const objSe = {};
    objSe[arr[i].key] = arr[i].value;
    trueObj = Object.assign({}, trueObj, objSe);
  }
  return trueObj;
};
// 获取图片前缀地址
export const imgUrl = (url) => {
  return `https://vsj-tests.oss-cn-zhangjiakou.aliyuncs.com/110/oss/store_user_h5/${url}`;
};
// 获取系统配置
// export const getConfig = () => {
//   const config = uni.getStorageSync('config');
//   if (config) {
//     return config;
//   } else {
//     api('system/query', {
//       type: '6'
//     }, 'POST').then(res => {
//       if (res.code == 200) {
//         uni.setStorageSync('sysConfig', res.data);
//         uni.setStorageSync('config', arrToObj(res.data));
//         return arrToObj(res.data);
//       }
//     });
//   }
// };

// 手机号验证
export function isPoneAvailable (telephone) {
    const myreg = /^1[0-9]{10}$/;
    if (!myreg.test(telephone)) {
        console.log(telephone);
        return false;
    } else {
        return true;
    }
}
