import Vue from 'vue';
import router from '../router';
import store from '../store';

/** 权限指令 **/
Vue.directive('isLogin', {
    bind: function (el, binding, vnode) {
        el.onclick = function (e) {
            if (store.getters['user/isLogin']) {
                binding.value();
            } else {
                router.push({
                    name: 'Login',
                    params: {
                        showToast: '请您先登录'
                    }
                });
            }
            e.stopPropagation();
        };
    }
});
