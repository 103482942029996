<template>
    <div class="list">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-span="没有更多了"
            @load="onLoad"
        >
            <div v-for="(item, index) in list" :key="index" class="record">
                <div class="record_top">
                    订单号: {{item.orderNo}}
                </div>
                <div class="main" :class="item.state === 3 ? 'border_bottom_gray' : ''">
                    <div class="pro_name">
                        商品名称: {{item.productName}}
                    </div>
                    <div class="pro_name">
                        购买数量: {{item.quantity}}
                    </div>
                    <div class="pro_date">
                        购买时间: {{item.paymentTime}}
                    </div>
                    <div class="pro_person">
                        收货人: {{item.receiverName}}
                    </div>
                    <div class="pro_phone">
                        联系电话: {{item.receiverPhone}}
                    </div>
                    <div class="pro_phone">
                        订单状态: <span :class="'state_' + item.state">{{item.stateLabel}}</span>
                    </div>
                    <div class="pro_phone" v-if="item.expressCompany">
                        物流公司: {{item.expressCompany}}
                    </div>
                    <div class="pro_phone" v-if="item.expressNumber">
                        物流单号: {{item.expressNumber}}
                    </div>
                    <div class="pro_addr">
                        <div>配送地址: </div>
                        <div>
                            <div>{{item.receiverProvince}} {{item.receiverCity}} {{item.receiverRegion}}</div>
                            <div>{{item.receiverDetailAddress}}</div>
                        </div>
                    </div>
                    <div class="pro_total">
                        共计支付: <span> {{item.payAmount}} 积分</span>
                    </div>
                </div>
                <div class="receive_button_box" v-if="item.state === 3">
                    <button class="receive_button" @click="receive(item)">确认收货</button>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
    export default {
        name: 'ExchangeRecord',
        data () {
            return {
                loading: false,
                finished: false,
                list: [],
                params: {
                    pageNum: 1,
                    pageSize: 5
                }
            };
        },
        mounted () {},
        methods: {
            receive (item) {
                this.$axios.get(this.$requestUrl.product.receiveOrder, {
                    params: {
                        id: item.id
                    }
                }).then(res => {
                    this.$toast('确认收货成功');
                    this.params.pageNum = 1;
                    this.list = [];
                    this.onLoad();
                });
            },
            onLoad () {
                this.$axios.get(this.$requestUrl.product.getOrderList, {
                    params: {
                        ...this.params
                    }
                }).then(res => {
                    this.list = this.list.concat(res.data.records);
                    // 加载状态结束
                    this.loading = false;
                    this.params.pageNum += 1;
                    // 数据全部加载完成
                    if (this.list.length >= res.data.total) {
                        this.finished = true;
                    }
                }).catch(() => {
                    this.finished = true;
                });
            }
        },
        computed: {
            classNameParse: function (status) {

            }
        }
    };
</script>

<style scoped lang="scss">
.list{
    padding: 20px 40px;
}
.record{
    border-radius: 12px;
    box-shadow: 0 0 20px rgb(199, 199, 199);
    margin-bottom: 36px;
    padding: 24px;
}

.record_top{
    border-bottom: 1px solid rgb(216, 215, 215);
    padding-bottom: 18px;
}
.main{
    padding-top: 24px;
    color: gray;
    font-size: 28px;
}
.border_bottom_gray{
    border-bottom: 1px solid rgb(216, 215, 215);
    padding-bottom: 18px;
}
.pro_name, .pro_date, .pro_addr, .pro_person, .pro_phone{
    margin-bottom: 12px;
}
.pro_addr{
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
}
.pro_total{
    text-align: end;
    padding-right: 12px;
    margin-top: 12px;
}
.pro_total span{
    font-weight: bold;
    font-size: 38px;
    color: red;
    padding-left: 8px;
}
.receive_button_box{
    padding-top: 24px;
    display: flex;
    overflow: hidden;
    position: relative;
    height: 70px;
}
.receive_button{
    width: 220px!important;
    font-weight: normal!important;
    font-size: 24px!important;
    background-color: $main_color;
    height: 64px;
    color: #fff;
    display: block;
    border: none;
    position: absolute;
    right: 0;
    top: 24px;
}
.state_1{
    color: red;
}
.state_3{
    color: orange;
}
.state_4{
    color: green;
}
</style>
