<template>
    <div>
        <div class="list">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <van-cell v-for="(item, index) in list" :key="index" :title="item.createTime + ' ' + item.remark"></van-cell>
            </van-list>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfitList',
        data () {
            return {
                loading: false,
                list: [],
                finished: false,
                params: {
                    pageNum: 1,
                    pageSize: 20
                }
            };
        },
        methods: {
            onLoad () {
                this.$axios.get(this.$requestUrl.home.getRewardLogs, {
                    params: this.params
                }).then(res => {
                    this.list = this.list.concat(res.data.rows);
                    // 加载状态结束
                    this.loading = false;
                    this.params.pageNum += 1;
                    // 数据全部加载完成
                    if (this.list.length >= res.data.total) {
                        this.finished = true;
                    }
                }).catch(() => {
                    this.finished = true;
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.list{
    border-radius: 16px;
    background-color: white;
}
</style>
