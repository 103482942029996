<template>
    <div class="card-list">
        <van-cell value="详情" is-link v-for="item in list" :key="item.id" @click="goEdit(item)">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
                <div class="title-box">
                    <span class="custom-title">卡号: {{ item.payeeAcc }}</span>
                    <van-tag type="danger" color="#3a6ae4FF" v-if="item.type === 0">银行卡</van-tag>
                    <van-tag type="danger" color="#3a6ae4FF" v-if="item.type === 1">微信</van-tag>
                    <van-tag type="danger" color="#3a6ae4FF" v-if="item.type === 3">支付宝</van-tag>
                </div>
            </template>
        </van-cell>
        <div class="add-btn">
            <van-button type="primary" color="#3a6ae4FF" round @click="$router.push({name: 'AddWithdraw'})">添加提现方式</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardList',
        data () {
            return {
                chosenContactId: '1',
                list: []
            };
        },
        mounted () {
            this.$axios.get(this.$requestUrl.home.queryUserAccountList).then(res => {
                this.list = res.data;
            });
        },
        methods: {
            goEdit ({ id }) {
                this.$router.push({
                    name: 'AddWithdraw',
                    query: {
                        id
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.card-list{
    margin-bottom: 180px;
    .title-box{
        display: flex;
        align-items: center;
        .custom-title{
            padding-right: 20px;
        }
    }
    ::v-deep .van-cell__title{
        flex: 3;
    }
    .add-btn{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 60px;
        background-color: #fff;
        button{
            width: 100%;
        }
    }
}
</style>
