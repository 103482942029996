<template>
<div>
    <van-form>
        <van-field
            v-model="channelInfo.promoCode"
            name="推广码"
            label="推广码/员工编号"
            placeholder="无"
            readonly
        />
        <van-field
            v-model="channelInfo.superiorMobile"
            name="推荐人"
            label="推荐人"
            readonly
            placeholder="无"
        />
    </van-form>
</div>
</template>

<script>
    export default {
        name: 'ChannelInfo',
        data () {
            return {
                channelInfo: {}
            };
        },
        mounted () {
            this.$axios.get(this.$requestUrl.home.getChannelInformation).then(res => {
                this.channelInfo = res.data;
            });
        }
    };
</script>

<style scoped lang="scss">

</style>
