<template>
    <div>
        <van-address-list
            v-model="chosenAddressId"
            :list="list"
            :disabled-list="[]"
            default-tag-text="默认"
            @add="onAdd"
            @edit="onEdit"
            @select="clickItem"
        ></van-address-list>
    </div>
</template>

<script>
    export default {
        name: 'AddressList',
        data () {
            return {
                chosenAddressId: null,
                list: []
            };
        },
        mounted () {
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                message: '加载中...',
                forbidClick: true
            });
            this.$axios.get(this.$requestUrl.product.getUserAdressList).then(res => {
                this.$toast.clear();
                this.list = res.data.map(i => ({
                    id: i.id,
                    name: i.name,
                    tel: i.mobile,
                    address: `${i.province} ${i.city} ${i.region} ${i.detailAddress}`,
                    isDefault: i.isDefalut === 1
                }));
                const checkedId = this.$route.query.checkedId;
                console.log(checkedId, 'checkedId');
                if (res.data.length > 0) {
                    const curr = checkedId ? res.data.find(i => i.id.toString() === checkedId.toString()) : res.data.find(i => i.isDefalut === 1);
                    console.log(curr, 'curr');
                    if (curr) {
                        this.chosenAddressId = curr.id;
                    } else {
                        this.chosenAddressId = res.data[0].id;
                    }
                }
            });
        },
        methods: {
            onAdd (v) {
                this.$router.push({
                    name: 'CreateAddress'
                });
            },
            clickItem (v) {
                console.log(v, 'click');
                sessionStorage.setItem('addressId', v.id);
                this.$router.go(-1);
            },
            onEdit (v) {
                console.log(v);
                this.$router.push({
                    name: 'CreateAddress',
                    query: {
                        addressId: v.id
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.btn-group{
    display: flex;
    align-items: center;
    ::v-deep button{
        width: 45%;
    }
}
::v-deep .van-address-item .van-radio__icon--checked .van-icon{
    background-color: $main_color;
    border: 1px solid $main_color;
}
::v-deep .van-tag{
    background-color: $main_color;
}
::v-deep .van-address-list__bottom{
    button{
        background-color: $main_color;
        border: 1px solid $main_color;
    }
}
</style>
