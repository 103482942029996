<template>
<div class="button-box"><div class="apply-button">申请首刷礼</div></div
<div class="coupon-box">
    <ul>
        <li v-for="item in list" :key="item.id" @click="goCouponDetail(item)">
            <h3>{{item.couponName}}</h3>
            <p style="padding: 0;">
                <span class="key">兑换码: </span>
                <span class="value">{{item.code}}</span>
            </p>
            <p>
                <span class="key">创建时间: </span>
                <span class="value">{{item.createTime}}</span>
            </p>
            <div class="status">
                <span :class="[item.state === 1 ? 'red-color' : '']">{{item.state === 1 ? '待领取' : '已领取'}}</span>
            </div>
        </li>
    </ul>
</div>
</template>

<script>
    export default {
        name: 'CouponList',
        data () {
            return {
                list: []
            };
        },
        created () {
            this.$toast.loading({
                message: '加载中'
            });
            this.$axios.get(this.$requestUrl.product.getUserCouponList).then(res => {
                console.log(res);
                this.list = res.data;
            }).finally(() => {
                this.$toast.clear();
            });
        },
        methods: {
            goCouponDetail ({ id }) {
                this.$router.push({
                    name: 'CouponDetials',
                    params: {
                        id
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.coupon-box{
    ul{
        padding: 40px;
        li{
            margin-bottom: 24px;
            padding: 20px  180px 20px 34px;
            background-color: white;
            border: 1px solid #d5d5d5;
            border-radius: 16px;
            position: relative;
            background-color: red;
            h3{
                margin-top: 2px;
                margin-bottom: 14px;
                font-size: 30px;
                //border-bottom: 1px solid #d2d2d2;
            }
            p{
                padding-top: 10px;
                .key{
                    font-size: 28px;
                    font-weight: bold;
                }
                .value{
                    color: gray;
                    font-size: 26px;
                }
            }
            .status{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 160px;
                border-left: 1px solid #bfbfbf;
                @include flexCenter;
            }
        }
    }
}
</style>
