<template>
    <div>
        <div v-if="isWxLogin">
            <div class="top-card"></div>
            <div class="card">
                <div class="content">
                    <img :src="userInfo.headUrl" alt="" class="avatar">
                    <div class="data-info">
                        <span class="name-box">姓名：{{userInfo.name}}</span>
                        <span>手机：{{userInfo.phone}}</span>
                    </div>
                </div>
                <div class="money-box">
                    <div>收益积分: ￥{{money.remainAmount}}</div>
                    <div>收益总计: ￥{{money.totalAmount}}</div>
                </div>
                <div class="button-group">
                    <div @click="withdraw">
                        <span>积分提现</span>
                        <span>></span>
                    </div>
                    <div @click="goExchangeRecord">
                        <span>兑换记录</span>
                        <span>></span>
                    </div>
                </div>
            </div>
            <ul class="main-ul">
           <!--     <li class="steps">
                    <img :src="require('@/assets/images/steps.png')" alt="">
                </li> -->
                <li class="list">
                    <ul>
                        <li v-for="item in list" :key="item.id" @click="item.click" v-if="item.show">
                            <img :src="item.img" alt="" v-if="item.img">
                            <van-icon :name="item.icon" v-if="item.icon" class="icon-item"/>
                            <div>{{item.name}}</div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div v-else>
            <div class="bind">
                <a :href="'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + $config.appId +'&redirect_uri=' + encodeURIComponent('https://guang.zopsen.com/wechat_login') + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'">点击绑定微信信息</a>
            </div>
        </div>
        <van-overlay :show="show" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="form-box">
                    <van-form @submit="onSubmit">
                        <van-field
                            v-model="userTel"
                            name="userTel"
                            label="发放人手机号"
                            type="tel"
                            placeholder="填写发放人手机号"
                        />
                        <div class="submit-button">
                            <van-button round block type="info" native-type="submit" size="small" class="submit-button-real">提交</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import {isPoneAvailable} from "../../common/utils";
    export default {
        name: 'PersonalCenter',
        data () {
            return {
                show: false,
                xiyishow:false,
                xinyishowcontent:true,
                agree2:false,
                money: {},
                userTel: '',
                list: [
                    {
                        id: 1,
                        name: '推荐记录',
                        img: require('@/assets/images/record-invioce.png'),
                        click: this.goRecommend,
                        show: true
                    },
                    {
                        id: 2,
                        name: '收益明细',
                        img: require('@/assets/images/profit_list.png'),
                        click: this.goProfitList,
                        show: true
                    },
                    // {
                    //     id: 3,
                    //     name: '帮助中心',
                    //     img: require('@/assets/images/helper.png'),
                    //     click: this.goHelp,
                    //     show: true
                    // },
                    {
                        id: 4,
                        name: '渠道信息',
                        icon: 'chat-o',
                        click: this.goChannel,
                        show: true
                    },
                    // {
                    //     id: 5,
                    //     name: '扫描核销码',
                    //     icon: 'scan',
                    //     click: this.scan,
                    //     show: this.$store.state.user.userInfo.deptId !== null && this.$store.state.user.userInfo.deptId !== 1
                    // },
                    // {
                    //     id: 6,
                    //     name: '优惠券列表',
                    //     icon: 'coupon-o',
                    //     click: this.goCoupon,
                    //     show: true
                    // },
                    {
                        id: 8,
                        name: '退出登录',
                        icon: 'revoke',
                        click: this.exit,
                        show: true
                    }
                ]
            };
        },
        mounted () {
            this.$axios.get(this.$requestUrl.home.getUserAccount).then(res => {
                this.money = res.data;
            });
        },
        methods: {
            goHelp () {
                this.$router.replace({
                    name: 'Helper'
                });
            },
            sendFirstGift () {
                this.show = true;
            },
            exit () {
                this.$store.commit('user/setUserInfoWechat', {});
                this.$router.replace({
                    name: 'Login'
                });
            },
            onSubmit () {
                if (!this.userTel) {
                    this.$toast('请填写手机号');
                    return;
                }
                if (!isPoneAvailable(this.userTel)) {
                    this.$toast('请填写正确的手机号');
                    return;
                }
                const {
                    $axios,
                    $requestUrl
                } = this;
                this.$toast.loading({
                    message: '加载中',
                    forbidClick: true
                });
                $axios.get($requestUrl.product.grantFirstbrush, {
                    params: {
                        mobile: this.userTel
                    }
                }).then(res => {
                    if (res.code !== '101') {
                        this.$toast(res.message);
                    } else {
                        this.$toast('发放成功');
                        this.userTel = '';
                        this.show = false;
                    }
                }).catch(() => {
                    this.$toast('发放失败');
                }).finally(() => {
                    this.$toast.clear();
                });
            },
            scan () {
                const THIS = this;
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        const id = res.resultStr;
                        THIS.$toast.loading({
                            message: '使用中'
                        });
                        THIS.$axios.get(THIS.$requestUrl.product.consumeCoupon, {
                            params: {
                                userCouponId: id
                            }
                        }).then(res => {
                            THIS.$toast.clear();
                            THIS.$toast(res.message);
                        }).catch(() => {
                            THIS.$toast.clear();
                        });
                    }
                });
            },
            goChannel () {
                this.$router.push({
                    name: 'ChannelInfo'
                });
            },
            goProfitList () {
                this.$router.push({
                    name: 'ProfitList'
                });
            },
            sendCouponRecord() {
                this.$data.xiyishow = true;
                // this.$router.push({
                //     name: 'CouponRecord'
                // });
            },
            becomeXySubmit2() {
                this.$data.xiyishow = false
                this.$router.push({
                    name: 'CouponRecord'
                });
            },
            withdraw () {
                if (this.userInfo.type !== 3) {
                    this.$toast('请先成为合伙人');
                    return;
                }
                if (!this.userInfo.issign) {
                    this.$router.push({
                        name: 'Identity'
                    });
                    return;
                }
                this.$router.push({
                    name: 'WithdrawHome'
                });
            },
            goExchangeRecord () {
                if (this.userInfo.type !== 3) {
                    this.$toast('请先成为合伙人');
                    return;
                }
                this.$router.push({
                    name: 'ExchangeRecord'
                });
            },
            goCoupon () {
                this.$router.push({
                    name: 'CouponList'
                });
            },
            goRecommend () {
                if (this.userInfo.type !== 3) {
                    this.$toast('请先成为合伙人');
                    return;
                }
                this.$router.push({
                    name: 'RecommendRecord'
                });
            }
        },
        computed: {
            ...mapGetters('user', ['isWxLogin']),
            ...mapState('user', ['userInfo'])
        }
    };
</script>

<style scoped lang="scss">
.top-card{
    height: 200px;
    background-color: $main_color;
}
.card{
    background-image: url("../../assets/images/card_bg.png");
    width: 100%;
    height: 380px;
    background-size: 100% 100%;
    margin-top: -150px;
    padding: 0 40px;
    box-sizing: border-box;
    position: relative;
    .avatar{
        border-radius: 50%;
        border: 4px solid #be7bf3;
        width: 120px;
        height: 120px;
    }
    .content{
        padding: 50px 30px 0;
        display: flex;
        align-items: center;
        .data-info{
            color: #fff;
            display: flex;
            flex-direction: column;
            font-size: $label_title_font_size;
            padding-left: 24px;
            .name-box{
                margin-bottom: 28px;
            }
        }
    }
    .money-box{
        display: flex;
        padding-top: 24px;
        align-items: center;
        div{
            width: 50%;
            text-align: center;
            color: #630094;
            font-size: $normal_font_size;
        }
    }
    .button-group{
        position: absolute;
        bottom: 44px;
        height: 76px;
        left: 32px;
        right: 32px;
        border-top: 1px solid #ab56e9;
        display: flex;
        align-items: center;
        text-align: center;
        div{
            width: 50%;
            color: #fff;
            &:first-child{
                border-right: 1px solid #ab56e9;
            }
            span:first-child{
                padding-right: 40px;
            }
        }
    }
}
.main-ul{
    padding: 0 40px;
    margin-top: 20px;
    padding-bottom: 60px;
    .steps{
        padding: 20px 0;
        border-radius: 12px;
        background-color: #fff;
        img{
            width: 100%;
        }
    }
    ul{
        li{
            display: flex;
            align-items: center;
            padding: 24px 30px;
            background-color: #fff;
            border-radius: 0.5em;
            margin-top: 24px;
            font-size: 28px;
            font-weight: bold;
            background-image: url("../../assets/images/right-arrow-personal.png");
            background-size: 14px 25px;
            background-position: 94% center;
            background-repeat: no-repeat;
            img{
                width: 46px;
                height: 46px;
                margin-right: 24px;
            }
            .icon-item{
                color: $main_color;
                font-size: 42px;
                margin-right: 24px;
                font-weight: bold;
            }
        }
    }
}
.bind{
    width: 100%;
    text-align: center;
    height: 100vh;
    @include flexCenter;
    @include allFitContent;
    a{
        color: blue;
    }
}
.form-box{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background-color: #fff;
    width: 90%;
    height: 250px;
    padding: 48px 0;
    border-radius: 15px;
}
.submit-button{
    padding-top: 44px;
    max-width: 480px;
    margin: 16px auto;
    .submit-button-real{
        background-color: $main_color;
        border-color: $main_color;
    }
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    .content {
        padding: 0 20px;
        width: 95vw;
        background: white;
        height: 1000px;
        .modal-rich-txt {
            margin-top: 20px;
            overflow-y: scroll;
            height: 620px;
            line-height: 32rpx;
        }
        .check-box{
            margin-top: 10px;
            padding: 20px;
        }
        .button-sunbmit{
            @include flexCenter;
            .button-sunbmit-btn{
                height: 68px;
                width: 180px;
            }
        }
    }
    .close{
        margin-top: 24px;
        color: #fff;
        height: 48px;
        width: 48px;
        @include flexCenter;
        border-radius: 50%;
        border: 1px solid #fff;
        font-size: 34px;
        background-color: red;
    }
    .poster{
        width: 80%;
        img{
            width: 100%;
        }
    }
}
</style>
