<template>
    <div class="login-page">
        <div class="logo-img">
            <img :src="require('@/assets/images/logo.png')" alt="">
        </div>
        <div class="login-form">
            <div class="form-title">HELLO,</div>
            <div class="form-title">请选择身份登录</div>
            <div class="form-box">
                <van-form @submit="login">
                    <div class="field-item">
                        <van-field
                            v-model="formData.mobile"
                            name="mobile"
                            placeholder="输入手机号"
                        />
                    </div>
                    <div class="field-item">
                        <van-field
                            v-model="formData.code"
                            name="code"
                            placeholder="输入手机验证码"
                        >
                            <template #button>
                                <span class="getCode" @click="getCode" v-show="getTimer === null">立即获取</span>
                                <span class="get-code-disable" v-show="getTimer !== null">{{ timer }}s后可重新获取</span>
                            </template>
                        </van-field>
                    </div>
                    <div style="display: flex;flex-direction: row;font-size: 14px;"><van-checkbox v-model="formData.isagree" shape="square" checked-color="#3a6ae4"></van-checkbox> <div style="margin-left:15px">我已阅读并同意<a href="#" @click="show = true">服务协议</a>和<a href="#" @click="xiyishow = true">隐私政策</a></div></div>
                    <div class="button-box">
                        <van-button round block type="info" native-type="submit" class="vsj-main-primary-color">登录</van-button>
                    </div>
                </van-form>
            </div>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: center;padding-left: 15px;padding-right: 15px;">
            <div class="warm"><div class="warm-title">温馨提示：</div><div class="warm-content"><p><span>1、必须真实填写个人信息，且必须跟申卡信息保持一致，不得包装使用虚假信息，否则无法在平台办理任何业务；</span><span></span></p>
            <p><span>2、申请条件：18-60岁（含）具有完全民事行为能力的自然人，有稳定工作，有良好征信记录；</span></p>
            <p><span>3、申请人应完整填写申请资料，并确保所填信息真实准确（如单位和</span><span>住址一定要填写真实、详细）；</span></p>
            <p><span>4、会员资料与银行无关，本平台对此资料提供隐私保护；</span></p>
            <p></p></div></div>
        </div>
       <van-overlay :show="show"  @click="show = false" :lock-scroll="false">
           <div class="wrapper" @click.stop>
               <div class="content" style="font-size: 14px;">
                   <p
                       style="margin: 0px 0px 15px; text-align: center; background: white; border: none; padding: 0px 0px 15px; line-height: 1.75em;">
                       <span style="font-family:'微软雅黑',sans-serif">用户注册服务协议</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>本平台由首山（北京）科技有限公司及运营，我们依照本协议向您提供本协议涉及的相关服务。请您使用本平台服务前仔细阅读本协议。<strong>您只有完全同意本协议，才能成为本平台的注册用户（"用户"）并使用相应服务。您在注册为本平台用户过程中点击对应按钮即表示您已仔细阅读并明确同意遵守本协议以及经参引而并入其中的所有条款、政策以及指南，并受该等规则的约束（合称"本协议"）</strong>。<strong>我们可能根据法律法规的要求或业务运营的需要，对本协议不时进行修改。本协议在您与我们间成立并发生法律效力，同时您成为我们的正式用户。除非另有规定，修改后的内容一旦以任何形式由本平台发布之时立即生效。您应不时关注本平台公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意修改后的内容，应立即停止我们的服务；如您继续我们的服务，即视为知悉修改内容并同意接受。</strong></span>
                   </p>
                   <p style="line-height: 1.75em;"><strong><span
                               style="font-size:14px;font-family:'微软雅黑',sans-serif">在您注册成为本平台用户前请务必仔细查阅以下条款，尤其是与您的权益有或可能有重大关系或对本平台具有或可能具有免责或限制责任的加粗条款，请特别注意。一旦您在本平台进行用户注册，即表示您已阅读本协议所有条款，并对本协议条款的含义及相应的法律后果已全部通晓并充分理解，同意受本协议约束。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>一、服务内容</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>1.1</span><span>本平台为您提供银行信用卡、贷款产品、积分兑换产品、聚合支付产品、号卡产品、金融APP展示及申请/办理入口等一系列服务（以下简称“服务内容”，具体服务内容以平台页面展示为准），具体服务形式包括但不限于为您提供相关服务链接或将您提交至本平台的个人信息反馈至银行信用卡中心或服务内容提供机构，以此为您提供本协议约定的服务内容。本平台的具体服务内容由我们根据实际情况提供并不时更新，包括但不限于信息、图片、文章、评论、链接等，我们将定期或不定期根据用户的意愿以电子邮件、短信、电话或站内信等方式为用户提供活动信息，并向用户提供相应服务。我们对提供的服务拥有最终解释权。</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>1.2</span><span>除我们书面同意，您或其他用户均不得将本平台上的任何信息用于商业目的。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>1.3</span><span>您使用本平台服务时所需的相关的设备以及网络资源等（如个人电脑、手机及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均由您自行负担。</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>二、使用准则</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>2.1</span><span>您在使用本平台服务过程中，必须遵循国家的相关法律法规，不得出现以下行为：</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(1)</span><span>反对宪法所确定的基本原则的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(2)</span><span>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(3)</span><span>损害国家荣誉和利益的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(4)</span><span>煽动民族仇恨、民族歧视，破坏民族团结的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(5)</span><span>破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(6)</span><span>散布谣言，扰乱社会秩序，破坏社会稳定的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(7)</span><span>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的、欺诈性的或以其它令人反感的讯息、数据、信息、文本、音乐、声音、照片、图形、代码或其它材料；</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(8)</span><span>侮辱或者诽谤他人，侵害他人合法权益的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(9)</span><span>其他违反宪法和法律、行政法规或规章制度的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(10)</span><span>可能侵犯他人的专利、商标、商业秘密、版权或其它知识产权或专有权利的；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(11)</span><span>假冒任何人或实体或以其它方式歪曲您与任何人或实体之关联性的内容；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(12)</span><span>未经授权发布第三方的私人信息，包括但不限于地址、电话号码、电子邮件地址、身份证号以及银行卡卡号；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(13)</span><span>发布病毒、不可靠数据或其它有害的、破坏性的或危害性的文件。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>2.2</span><span>您不得利用本平台的服务从事下列危害互联网信息网络安全的活动：</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(1)</span><span>未经允许，进入互联网信息网络或者使用互联网信息网络资源；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(2)</span><span>未经允许，对互联网信息网络功能进行删除、修改或者增加；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(3)</span><span>未经允许，对进入互联网信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(4)</span><span>故意制作、传播计算机或手机病毒等破坏性程序；</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>(5)</span><span>其他危害互联网信息网络安全的行为。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>2.3</span></strong><strong><span>您须对自己在使用本平台服务过程中的行为承担法律责任。若您为限制行为能力或无行为能力者，则您的法定监护人应承担相应的法律责任。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                      <strong><span >2.4</span></strong><strong><span>如您的操作影响系统总体稳定性或完整性，我们将暂停或终止您的操作，直到相关问题得到解决。</span></strong>
                   </p>
                   <p style="background: white; line-height: 1.75em;"><strong><span>2.5
                               您不应将您账号转让、出售或出借予他人使用，帐号使用权仅属于初始用户，您应对帐号内的全部行为独立承担全部责任。</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span
                               style="font-family:'微软雅黑',sans-serif">三、免责声明</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><span>3.1
                       </span><span>如您违反本服务协议、法律法规、道德准则，您应自行承担由此所产生的责任和损失，并赔偿我们、我们的其他用户及相关第三方因您的行为所产生的全部损失。</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><span>3.2
                       </span><span>对任何第三方通过本平台，因违反本服务协议、法律法规、道德标准而对您造成的任何损害，我们不对此承担责任。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>3.3
                           </span></strong><strong><span>对黑客行为、计算机或手机病毒、或因您保管疏忽致使帐号、密码被他人非法使用、盗用、篡改或丢失，或由于与本平台链接的其它网站或平台所造成您个人资料的泄露，或您因其他非本平台原因造成的损失，我们不承担责任。如您发现任何非法使用用户帐号或安全漏洞的情况，请立即与我们联系。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>3.4
                           </span></strong><strong><span>我们不保证服务一定能满足您的要求；也不保证服务不会中断。</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>3.5</span></strong><strong><span>任何情况下，因使用本平台而引起或与使用本平台有关的服务而产生的由我们负担的责任总额，无论是基于合同、保证、侵权、产品责任、严格责任或其它理论，均不得超过您因访问或使用本APP而向我们支付的任何服务费用。用户对本协议的签署意味着用户自行承担使用本平台服务时可能产生的各种风险，本平台对用户不承担任何责任。但本平台会尽最大努力，对所提供的网络服务维护其及时性、安全性、准确性。对于因不可抗力或本平台不能控制的原因造成的网络服务中断或其他缺陷，本平台不承担任何责任，但将尽力减少因此给用户造成的损失和影响。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><span>3.6
                       </span><span>您在使用本平台提供的各项服务内容过程中，遇到的任何预先收费均为诈骗行为，请保持警惕避免损失。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>四、服务变更、中断或终止</span></strong></p>
                   <p style="background: white; line-height: 1.75em;"><span
                           style="font-size:14px;font-family:'微软雅黑',sans-serif">4.1</span><span
                           style="font-size:14px;font-family:'微软雅黑',sans-serif">如因升级的需要而需暂停服务、或调整服务内容，我们将尽可能在本平台上进行通告。由于您未能及时浏览通告而造成的损失，我们不承担任何责任。</span>
                   </p>
                   <p style="background: white; line-height: 1.75em;"><span
                           style="font-size:14px;font-family:'微软雅黑',sans-serif">4.2</span><span
                           style="font-size:14px;font-family:'微软雅黑',sans-serif">您明确同意，我们保留根据实际情况随时调整本平台提供的服务内容、种类和形式，或自行决定授权第三方向您提供原本我们提供的服务。服务一经变更，我们将会在重要页面上提示修改内容，不再另行单独通知，您应不时注意本协议条款及相关规则的变动。如果不同意所改动的内容，您可以主动取消获得的服务。如果您继续享用本平台提供的服务，则视为接受服务条款的变动。本平台保留随时修改或中断免费服务而不需提前通知用户的权利。本平台行使修改或中断免费服务的权利，不需对用户或第三方负责。</span>
                   </p>
                   <p style="background: white; line-height: 1.75em;"><strong><span
                               style="font-size:14px;font-family:'微软雅黑',sans-serif">4.3</span></strong><strong><span>发生下列任何一种情形，我们有权单方面中断或终止向您提供服务而无需通知您，且无需对您或第三方承担任何责任：</span></strong>
                   </p>
                   <p style="background: white; line-height: 1.75em;"><strong><span>(1)您提供的个人资料不真实；</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>(2)您的行为违反本协议；</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>(3)您的行为违反法律法规规定。</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>4.4
                           </span></strong><strong><span>您有权在满足条件的情况下通知我们终止向您提供服务，或直接取消本平台服务。针对您终止或取消服务之日后的行为，我们不再向您承担任何形式的责任。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>五、知识产权及其它权利</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>5.1</span><span>我们是本平台产品及服务的知识产权权利人。全部著作权、商标权、专利权、商业秘密等知识产权，以及包括但不限于文字、图片、视频、界面设计、版面框架、有关数据或电子文档均受相关法律法规和国际条约保护。未经我们书面同意，您不得为任何商业及或非商业目的自行或许可第三方实施、利用、转让上述知识产权，我们保留追究上述法律责任的权利。</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>5.2</span><span>未经相关内容权利人的事先书面同意，您不得擅自复制、传播在本平台的该等内容，或将其用于任何商业目的，所有这些资料或资料的任何部分仅可作为个人或非商业用途而保存在某台计算机或其他电子设备内。否则，我们及/或权利人将追究您的法律责任。</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>六、信息提供和隐私保护</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>6.1</span><span>您在访问、使用我们或申请使用本平台服务时，必须提供您真实的个人信息，且您应该根据实际变动情况及时更新个人信息。保护用户隐私是我们的重点原则，我们通过各种技术手段和强化内部管理等办法提供隐私保护服务功能，充分保护您的个人信息安全。</span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>6.2</span><span>您应保证您提供的个人信息的真实性、准确性或完整性，因信息不真实、不准确或不完整而引起的任何问题及其后果，由您自行承担，且您应保证我们免受由此而产生的任何损害或责任。<strong>若我们发现您提供的个人信息是虚假、不准确或不完整的，我们有权自行决定终止向您提供服务并要求您赔偿我们的损失。</strong></span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>6.3</span></strong><strong><span>为提供服务</span></strong><strong><span>、履行协议、解决争议、保障交易安全等目的</span></strong><strong><span>，您同意授权我们对您在平台填写的个人资料（主要指您的姓名、手机号、身份证号，具体以您在平台页面填写的信息为准）享有留存、整理加工、使用的权利，并授权我们向为您提供服务的第三方（以下简称“服务方”，具体服务方以之后列明的授权条款显示的服务方及页面文案显示的服务方为准）提供为完成该项服务必要的信息，且您明确授权我们及其服务方可将获取的个人资料用于为您提供的其他服务。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>6.4
                           </span></strong><strong><span>在您申请银行信用卡时，为了避免填写重复信息给您申卡操作带来的不便，您同意授权我们将您的个人资料（主要指您的姓名、手机号、身份证号，具体以申卡界面显示为准）提供给为您提供银行信用卡服务的银行（以下简称“银行”），且您明确授权银行可将获取的个人资料用于为您提供的信用卡服务中。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>6.5
                           </span></strong><strong><span>您同意并授权我们及服务方在您使用本协议项下的信用卡办理服务时，通过您提供的资料向为您提供信用卡办理的服务机构查询关于您办理信用卡的申卡状态等信息（主要包括您所申请信用卡是否是首卡、具体审批状态、是否激活成功、是否完成首刷以及您本人是否为银行新户，以下统称为“申卡状态信息”），以方便您通过我们平台直接查询您的申卡状态。同时，为了能够更好地为您提供查询服务，您同意自您信用卡办理成功后，我们可以留存您的申卡状态等信息。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>6.6
                           </span></strong><strong><span>为了保证推荐你申请信用卡的推荐人（以下简称“推荐人”）更好地为您提供推荐服务，您同意我们平台向推荐人展示您的姓名、手机号以及您所申请信用卡的具体审批状态。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><strong><span>6.7
                           </span></strong><strong><span>如您通过其他方推荐至我们平台申请信用卡，为了您在其他方页面可以查询您的申卡状态或者获得其他方给您发放的申卡奖励等，您同意我们将您办理信用卡的申卡状态信息提供给推荐您至我们平台办理信用卡的其他方，且您明确授权其他方可将获取的申卡状态信息用于为您提供的服务中。</span></strong>
                   </p>
                   <p style="text-align: left; line-height: 1.75em;"><span>6.8
                       </span><span>我们保证采用行业惯例以保护您的资料，<strong>但您理解，鉴于技术限制，我们无法确保用户的个人信息完全不被泄露。</strong></span>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>七、特别约定</span></strong></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>7.1</span></strong><strong><span>您使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方的合法权益的情形时，我们有权暂停或终止向您提供服务。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>7.2</span><span>您同意我们通过重要页面的公告、通告、电子邮件以及常规信件的形式向您传送与我们服务有关的任何通知和通告。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>7.3</span><span>如您有任何有关与我们服务的个人信息保护相关投诉，请您与我们联系，我们将在接到投诉之日起30日内进行答复。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;"><span>7.4
                       </span><span>本协议之效力、解释、执行均适用中华人民共和国法律。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>7.5</span><span>若非我们更新本协议，您在确认同意、签署本协议后，其效力将及于您此时及未来登陆我们时所有操作。</span></p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <strong><span>7.6</span></strong><strong><span>如就本协议内容或其执行发生任何争议，应尽量友好协商解决。</span></strong>
                   </p>
                   <p style="text-align: left; background: white; line-height: 1.75em;">
                       <span>7.7</span><span>本协议中的标题仅为方便而设，不影响对于条款本身的解释。本协议最终解释权归我们所有。</span></p>

               </div>
             <div class="close" @click="show = false">X</div>
           </div>
       </van-overlay>
          <van-overlay :show="xiyishow"  @click="xiyishow = false" :lock-scroll="false">
              <div class="wrapper" @click.stop>
                  <div class="content" style="font-size: 14px;">
                    <div class="s-content"><div class="s-content-box"><div class="s-content-list"><p style="text-align: center;"><strong><b>个人信息保护政策</b></strong></p>
                    <p><strong><b>导</b></strong><strong><b>言</b></strong></p>
                    <p>欢迎您使用首卡平台（以下简称“首卡”或“我们”）！我们深知个人信息保护对您的重要性，并会尽我们最大努力保护您的个人信息安全，我们将依法采取安全保护措施，保护您的个人信息及隐私安全，并将恪守以下原则：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。</p>
                    <p>在您使用首卡相关服务时，我们将按本政策收集、使用、存储、共享、转让、披露及保护您的个人信息。我们希望通过本政策向您介绍我们对您个人信息的处理和保护方式。因此，我们提请您：<strong><u><b>在</b></u></strong><strong><u><b>您使用</b></u></strong><strong><u><b>首卡相关服务、提交个人信息前</b></u></strong><strong><u><b>，仔细阅读并充分理解</b></u></strong><strong><u><b>本政策</b></u></strong><strong><u><b>，尤其是其中的加粗及下划线呈现的条款</b></u></strong><strong><u><b>，</b></u></strong><strong><u><b>在确认充分理解并同意后方使用相关服务。阅读《个人信息保护政策》的过程中，如您不同意《个人信息保护政策》或其中任何条款，应立即</b></u></strong><strong><u><b>停止提交您的个人信息，</b></u></strong><strong><u><b>您点击</b></u></strong><strong><u><b>对应按钮</b></u></strong><strong><u><b>后即视为您接受本政策的内容。</b></u></strong></p>
                    <p><strong><b>本政策涉及的服务仅供18周岁（含）以上人士使用，如您未达到上述年龄的，请您立即停止使用本平台服务。</b></strong></p>
                    <p><strong><b>&nbsp;</b></strong></p>
                    <p><strong><b>目录</b></strong></p>
                    <p>我们希望通过本政策帮助您了解以下内容：</p>
                    <p>一、《个人信息保护政策》的适用范围</p>
                    <p>二、我们如何收集您的个人信息</p>
                    <p>三、我们如何使用您的个人信息</p>
                    <p>四、我们如何使用Cookie和同类技术</p>
                    <p>五、我们如何共享、转让、委托处理、公开披露您的个人信息</p>
                    <p>六、我们如何保护您的个人信息</p>
                    <p>七、您如何管理自己的个人信息</p>
                    <p>八、我们如何处理未成年人的个人信息</p>
                    <p>九、您个人信息的存储地点及时间</p>
                    <p>十、对第三方责任的声明</p>
                    <p>十一、本政策的适用及修订</p>
                    <p>十二、如何联系我们</p>
                    <p><strong><b>&nbsp;</b></strong></p>
                    <p><strong><b>正文</b></strong></p>
                    <p><strong><b>一、《个人信息保护政策》的适用范围</b></strong></p>
                    <p>1.1 &nbsp;本政策适用于由首卡及其关联方提供相关服务过程中对您的个人信息的收集、使用、存储、共享、转让、披露及保护等活动。</p>
                    <p>1.2 &nbsp;如信息无法单独识别您的个人身份，或结合其他信息也无法识别您的个人身份，其不属于法律意义上的个人信息；当您的信息可以单独或可以结合其他信息识别到您的个人身份时，或首卡将无法与任何特定个人建立联系的数据与其他您的个人信息结合使用时，这些信息在使用期间，将作为您的个人信息按照本政策处理与保护。</p>
                    <p>1.3 &nbsp;本政策不适用于任何第三方对您的个人信息的收集，以及任何第三方提供的服务或第三方的信息使用规则。</p>
                    <p><strong><b>&nbsp;</b></strong></p>
                    <p><strong><b>二</b></strong><strong><b>、我们如何收集您的个人信息</b></strong></p>
                    <p>2.1 &nbsp;申请产品</p>
                    <p>在您通过首卡平台申请个人金融产品时，您需要主动填写如下信息：<strong><u><b>姓名、身份证号</b></u></strong><strong><u><b>码</b></u></strong><strong><u><b>、手机号</b></u></strong><strong><u><b>码</b></u></strong>，用于我们对您进行初步的身份验证，并推送至您申请的金融产品服务提供方对您进行信用审核等与金融产品申请相关的服务目的。个别情况下，我们还会收集您的<strong><u><b>微信昵称、头像</b></u></strong>，用于给您发放微信立减金奖励，我们收集您的微信昵称、头像时，会采取合理方式向您告知。</p>
                    <p>在您通过首卡平台申请个人金融产品后，我们会从金融产品服务提供方处获取您的申卡状态信息，包括<strong><u><b>您所申请的信用卡是否为首卡、具体审批状态、是否激活成功、是否完成首刷</b></u></strong>，用于向您展示查询结果。</p>
                    <p>2.2 &nbsp;广告、客服与售后功能</p>
                    <p><strong><u><b>为使您更便捷地了解我们推出的活动，我们会收集您的手机号码，并</b></u></strong><strong><u><b>可能</b></u></strong><strong><u><b>通过短信、电话或网站通知的形式，向您推送活动</b></u></strong><strong><u><b>消息</b></u></strong><strong><u><b>。</b></u></strong>您可以选择是否授权我们收集您的手机号码并用于上述活动消息的推送，您如果选择拒绝接受推送，不会影响您其他服务的使用。如您不希望收到活动消息推送，可联系我们的客服咨询并按照本政策第七条第（一）款的约定退订上述活动消息的推送。</p>
                    <p>2.3 &nbsp;附加功能</p>
                    <p>为向您提供更便捷、个性化的服务，我们在您使用附加功能时可能会收集您的以下个人信息：</p>
                    <p>基于位置信息的个性化推荐功能：<strong><u><b>我们会收集您的位置信息（我们仅收集您当时所处的地理位置，不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，以为您推荐适合您的产品/服务。</b></u></strong></p>
                    <p><strong><u><b>&nbsp;</b></u></strong></p>
                    <p><strong><b>三</b></strong><strong><b>、我们如何使用您的个人信息</b></strong></p>
                    <p>3.1 &nbsp;为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账号安全，我们会在以下情形中使用您的信息：</p>
                    <p>（1）实现本政策中“我们如何收集您的个人信息”所述目的。</p>
                    <p>（2）为了使您知晓使用我们服务的状态，我们会向您发送服务通知及提醒。</p>
                    <p>（3）根据法律法规或监管要求向相关部门进行报告。</p>
                    <p>（4）邀请您参与我们服务有关的客户调研。</p>
                    <p>（5）为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。</p>
                    <p>3.2 &nbsp;为了更好地向您提供服务，我们可能将某些服务中合法收集的您的个人信息经去标识化处理后结合起来，进行数据分析以形成用户画像，用来向您推荐您可能感兴趣的产品或服务。我们可能通过电话、手机短信、站内信、站内通知等形式向您推送我们认为您可能会感兴趣的个性化服务通知信息或商业性电子信息。如果您拒绝使用该服务，您可以退订该功能，我们将停止提供该服务，但不影响您使用我们提供的其他服务。</p>
                    <p>3.3 &nbsp;当我们要将您的个人信息用于本政策未载明的其它用途时，我们会按照法律规定通过确认协议、文案确认等形式再次征求您的明确授权同意。若您不同意再次授权，您可能无法使用该项服务，但不影响您使用其他服务。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>四</b></strong><strong><b>、我们如何使用Cookie和同类技术</b></strong></p>
                    <p>4.1 &nbsp;为实现您联机体验的个性化需求并使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为Cookie的小数据文件，指定给您的Cookie是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。Cookie通常包含标识符、站点名称以及一些号码和字符。我们向您发送Cookie是为了帮您节省重复输入注册、登录信息的步骤，帮助判断您的账户安全状态。</p>
                    <p>4.2 &nbsp;我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机或移动设备上保存的所有Cookie，还可以根据自己的需要来修改浏览器的设置以拒绝Cookie。但如果您这么做，您可能需要在每一次访问首卡时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性产生一定影响。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>五</b></strong><strong><b>、我们如何共享、转让、委托处理、公开披露您的个人信息</b></strong></p>
                    <p>5.1 &nbsp;共享</p>
                    <p>我们不会与我们以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。目前，我们可能在以下情形中，共享您的个人信息：</p>
                    <p>5.1.1 在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政机关、司法机关等监管部门的要求，对外共享您的个人信息。</p>
                    <p>5.1.2 与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供相关服务或代表我们履行职能，我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。</p>
                    <p>目前，我们的授权合作伙伴主要包括以下类型：</p>
                    <p>（1）金融产品服务机构。<strong><u><b>您通过</b></u></strong><strong><u><b>注册首卡</b></u></strong><strong><u><b>申请金融产品时，我们会根据您的选择，将您提供的姓名、手机号码、身份证号</b></u></strong><strong><u><b>码</b></u></strong><strong><u><b>共享给提供金融产品服务的机构，用于机构直接获得您的个人信息，避免您重复填写前述信息；</b></u></strong></p>
                    <p>（2）推荐您在首卡申请信用卡的推荐人。<strong><u><b>您根据</b></u></strong><strong><u><b>推荐人的</b></u></strong><strong><u><b>推荐通过</b></u></strong><strong><u><b>首卡</b></u></strong><strong><u><b>申请信用卡时，我们会将根据您的选择，将您的姓名、手机号</b></u></strong><strong><u><b>码</b></u></strong><strong><u><b>以及您所申请信用卡的具体审批状态共享给</b></u></strong><strong><u><b>推荐人</b></u></strong><strong><u><b>，促使</b></u></strong><strong><u><b>推荐人</b></u></strong><strong><u><b>给您提供更好的服务。</b></u></strong></p>
                    <p>（3）推荐您至我们平台申请信用卡的机构。<strong><u><b>您根据机构推荐通过</b></u></strong><strong><u><b>首卡申请</b></u></strong><strong><u><b>信用卡时，我们会根据您的选择，将您的姓名、手机号</b></u></strong><strong><u><b>码</b></u></strong><strong><u><b>以及您所申请信用卡的具体审批状态共享给推荐您申请信用卡的机构，用于为您提供信用卡申卡状态查询服务。</b></u></strong></p>
                    <p>对我们与之共享您个人信息的公司、组织和个人，我们会对其进行数据安全评估，要求他们按照本政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</p>
                    <p>5.2 &nbsp;转让</p>
                    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
                    <p>（1）在获取明确同意的情况下转让：在告知并获得您的明确同意后，我们会向经您同意的其他方转让您的个人信息；</p>
                    <p>（2）在涉及合并、收购、破产清算或类似交易时，如涉及到您个人信息转让，我们会向您告知有关情况，同时我们会求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</p>
                    <p>（3）基于法律法规、法律程序、诉讼或政府主管部门的强制性要求。</p>
                    <p>5.3 &nbsp;委托处理</p>
                    <p>我们服务中的某些具体的模块或功能可能由外部供应商提供，例如我们可能会聘请我们的关联方为我们提供基础设施技术服务、客户支持服务。对我们委托处理个人信息的公司、组织，我们会与其签署严格的保密协议，要求其遵守严格的保密义务并采取有效的保密措施，要求其只能在委托范围内处理您的信息，而不得出于其自身利益使用您的信息，并对受托人的个人信息处理活动进行监督。在委托合同不生效、无效、被撤销或者终止时，我们会要求受托方返还个人信息或者将个人信息予以删除，不得保留。</p>
                    <p>5.4 &nbsp;公开披露</p>
                    <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
                    <p>（1）获得您明确定同意后，以您明确同意的披露方式披露您所指定的个人信息；</p>
                    <p>（2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
                    <p>5.5 &nbsp;共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
                    <p><strong><u><b>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</b></u></strong></p>
                    <p>（1）与我们履行法律法规规定的义务直接相关的；</p>
                    <p>（2）与国家安全、国防安全直接相关的；</p>
                    <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
                    <p>（4）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
                    <p>（5）在法律法规允许的范围内，为维护您及首卡其他用户、首卡及其关联方、首卡合作方的生命、财产等重大合法权益或维权产品或服务的安全所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等，且难以得到您本人授权同意的；</p>
                    <p>（6）您自行向社会公众公开的个人信息；</p>
                    <p>（7）为向您提供相关产品/服务必须向第三方披露的；</p>
                    <p>（8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                    <p>开披露是指向社会或不特定人群发布信息的行为。除因为必要事宜而进行必要公开披露意外，原则上我们不会对您的个人信息公开披露。如因经法律授权或具备合理事由确需公开披露的，我们会在公开披露前向您告知公开披露的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容），并在征得您的明示同意后再公开披露，但法律法规另有规定的或本政策另有约定的除外。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>六</b></strong><strong><b>、我们如何保护您的个人信息</b></strong></p>
                    <p>6.1 &nbsp;我们非常重视您个人信息的安全，将努力采取各种符合业界标准的合理安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或被未经授权的访问、公开披露、使用、修改、损坏、丢失或泄漏。</p>
                    <p>6.2 &nbsp;我们会使用加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用受信赖的保护机制防止您的个人信息遭到恶意攻击。</p>
                    <p>6.3 &nbsp;我们会建立专门的信息安全工作小组、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。</p>
                    <p>6.4 &nbsp;<strong><b>尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息绝对安全，我们将尽力确保您提供给我们的个人信息的安全性。您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全。</b></strong></p>
                    <p>6.5 &nbsp;我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止任何安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。</p>
                    <p><strong><b>6.6 &nbsp;</b></strong><strong><u><b>我们谨此特别提醒您，本政策提供的个人信息保护措施仅适用于我们提供的相关服务。我们没有能力及义务保护您在相关服务之外的应用、网站提交的任何个人信息，无论您登录或浏览上述软件、网站是否基于我们的链接或引导，我们对此不承担任何法律责任。</b></u></strong></p>
                    <p>&nbsp;</p>
                    <p><strong><b>七、您如何管理自己的个人信息</b></strong></p>
                    <p>7.1 &nbsp;为使您知晓我们服务情况或向您推荐更优质的产品、服务，我们可能通过手机短信、站内信、站内通知等形式向您发送服务状态通知、营销宣传或其他电子信息。如您认为前述方式对您造成了打扰，您有权按照通知或信息当中提示的方式退订。</p>
                    <p>7.2 &nbsp;您在使用我们服务期间，有权查阅、复制、更正、删除您的个人信息。您可通过首卡平台“我的-联系客服”向我们提出有关申请，为保障安全，我们可能会先要求您验证自己的身份，然后再处理您的请求，我们会尽快审核您的申请并在验证您的用户身份后【15】日内予以处理。您申请删除个人信息并终止授权我们向您提供相关服务的，我们将在我们系统删除相关个人信息，并不再向您提供相关服务，但您终止授权的决定，不影响此前基于您的授权而开展的个人信息处理。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>八、我们如何处理未成年人的个人信息</b></strong></p>
                    <p>8.1 &nbsp;我们非常重视对未成年人个人信息的保护。我们的服务面向年满18周岁的成年人（且非为在校学生）。若您是18周岁以下的未成年人，我们建议您不要使用我们的服务。</p>
                    <p>8.2 &nbsp;若您必须要使用我们的服务时，应事先取得您的父母或法定监护人的书面同意或由您的父母或法定监护人代替您操作。我们将根据国家相关法律法规的规定保护未成年人的个人信息。</p>
                    <p>8.3 &nbsp;对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
                    <p>8.4 &nbsp;若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请及时与我们联系。</p>
                    <p>8.5 &nbsp;您及您的父母或法定监护人应对您所提供信息的真实性负责，如我们无法从您所提供信息判断出您为未成年人，那么我们不承担保护未成年人隐私权及个人信息的责任。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>九、您个人信息的存储地点及时间</b></strong></p>
                    <p>9.1 &nbsp;信息存储地点：我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。</p>
                    <p>9.2 &nbsp;存储期限：在您未提出注销注册用户资格或退订申请情况下，我们会安全有效的保存您的个人信息，仅在向您提供服务需要的情况下使用您的个人信息，但法律法规另有规定的除外。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>十、对第三方责任的声明</b></strong></p>
                    <p>请您注意，本政策仅适用于首卡自行向您提供的产品或服务。</p>
                    <p><strong><u><b>如果第三方服务商通过首卡</b></u></strong><strong><u><b>平台</b></u></strong><strong><u><b>为您提供服务，当您进入第三方服务商的服务页面时，请注意相关服务由第三方向您提供。涉及到第三方向您收集信息的，建议您仔细查看第三方的隐私政策或协议约定。</b></u></strong>当您查看第三方的服务页面（包括第三方开发的应用程序或第三方创建的网页）时，这些第三方可能会放置自己的Cookie或其同类技术，这些Cookie或其同类技术不受我们的控制，且其使用不受本政策的约束。我们将努力要求上述第三方主体对您的个人信息采取保护措施。如您发现上述第三方应用程序（或网页）存在风险时，建议您立即终止相关操作。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>十一、本政策的适用及修订</b></strong></p>
                    <p>11.1 首卡平台所有服务均适用本政策，但某些特定服务还将适用特定的隐私保护政策（例如授权书、授权委托书）我们将在向您提供这些特定服务前进一步向您说明并获取您的授权同意。如特定的隐私保护政策与本政策有不一致之处，以特定的隐私保护政策为准。特定的隐私保护政策未提及之处，以本政策为准。</p>
                    <p>11.2 本政策自您在相关页面填写个人信息并提交时起生效。</p>
                    <p>11.3 为了给您提供更好的服务，首卡平台相关服务将不时更新与变化，我们会适时对个人信息保护政策进行修订，该等修订构成个人信息保护政策的一部分并具有等同于个人信息保护政策的效力。但原则上我们不会削减你依据当前生效的个人信息保护政策所应享受的权利。</p>
                    <p>11.4 个人信息保护政策更新后，我们会通过在我们官网公告、推送通知、弹窗或其他适当的方式，说明个人信息保护政策的具体变更内容，以便您及时了解个人信息保护政策的最新版本。</p>
                    <p>&nbsp;</p>
                    <p><strong><b>十二、如何联系我们</b></strong></p>
                    <p>如果您对本政策或您在使用我们的服务过程中有任何疑问、意见或建议，或者需要向我们进行投诉、申诉，您可以通过首卡平台“我的-联系客服”通过联系在线客服的方式与我们取得联系，我们会尽快审核您的申请并在验证您的用户身份后【15】日内予以回复。</p>
                    <p>&nbsp;</p></div></div></div>
                      </div>
                         <div class="close" @click="xiyishow = false">X</div>
                      </div>
          </van-overlay>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import { isPoneAvailable } from '@/common/utils';
    import config from '@/common/config';
    import router from '../../router';
    export default {
        name: 'Login',
        data () {
            return {
                formData: {
                    mobile: '',
                    code: '',
                    isagree:false
                },
                show:false,
                 xiyishow:false,
                interval: null,
                timer: null,
                codeLoading: false // 请求验证码
            };
        },
        mounted () {
            // 如果想要登录页面提示 请在params里传入showToast
            if (this.$route.params.showToast) {
                this.$toast(this.$route.params.showToast);
            }
            console.log(this.$route.query, 'this.$route.query');
            // // 接收微信登录code
            // if (this.$route.query.code) {
            //     this.wechatLoginRequest();
            // }
            // 刷新页面需要重置定时器任务
            if (this.$store.state.system.timer !== null) {
                const second = moment(this.$store.state.system.timer).diff(moment(), 'second');
                if (second > 0) {
                    this.timer = second;
                    this.setIntervalCodeTime();
                } else {
                    this.$store.commit('system/setTimer', null);
                }
            }
        },
        methods: {
            ...mapActions(['getPoster']),
            onSubmit (data) {
                console.log(data);
            },
            async getCode () {
                if (this.codeLoading) {
                    return;
                }
                if (!this.testMobile()) {
                    return;
                }
                this.codeLoading = true;
                // 请求验证码
                const [err, codeData] = await this.$to(
                    this.$axios.get(this.$requestUrl.login.getCode, {
                        params: {
                            mobile: this.formData.mobile
                        }
                    })
                );
                // 捕获错误
                if (err) {
                    this.$toast('获取验证码出错');
                    this.codeLoading = false;
                    return;
                }
                // 验证码超时提示信息
                if (codeData.code !== '101') {
                    this.$toast(codeData.message);
                    this.codeLoading = false;
                    return;
                }
                // 开始计时器
                if (this.getTimer === null) {
                    this.$toast('短信验证码已发送');
                    this.timer = 59;
                    this.$store.commit('system/setTimer', moment().add(config.getCodeTime, 's').format('YYYY-MM-DD HH:mm:ss'));
                    this.codeLoading = false;
                    this.setIntervalCodeTime();
                }
            },
            // 设置定时器
            setIntervalCodeTime () {
                this.interval = setInterval(() => {
                    this.timer = moment(this.$store.state.system.timer).diff(moment(), 'second');
                    if (this.timer === 0) {
                        this.$store.commit('system/setTimer', null);
                        clearInterval(this.interval);
                    }
                }, 1000);
            },
            // 检测手机号
            testMobile () {
                // 检测是否为空
                if (!this.$v.formData.mobile.required) {
                    this.$toast.fail('请填写手机号');
                    return false;
                }
                // 检测是否为手机号
                if (this.$v.formData.mobile.$invalid) {
                    this.$toast.fail('请填写正确的手机号');
                    return false;
                }
                return true;
            },
            async login () {
                if (!this.testMobile()) {
                    return;
                }
                if (!this.formData.isagree) {
                    this.$toast.fail('请阅读协议并同意');
                    return;
                }
                // 检测验证码
                if (!this.$v.formData.code.required) {
                    this.$toast.fail('请填写验证码');
                    return;
                }
                this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true
                });
                const { mobile, code: captcha } = JSON.parse(JSON.stringify(this.formData));
                const [err, data] = await this.$to(
                    this.$axios.post(`${this.$requestUrl.login.userLogin}?mobile=${mobile}&channel=${window.location.href.indexOf('https://') !== -1 ? 'https' : 'http'}&captcha=${captcha}${this.$route.query.superior ? '&superior=' + this.$route.query.superior : ''}`)
                );
                if (err) {
                    this.$toast('登录异常');
                    this.$toast.clear();
                    return;
                }
                if (data.code !== '101') {
                    this.$toast(data.message);
                    this.$toast.clear();
                    return;
                }
                this.$toast.clear();
                // 保存用户信息
                this.$store.commit('user/setUserInfo', data.data);
                this.$store.commit('system/setTimer', null); // 清空时间
                this.$router.push({
                    name: 'Home'
                });
                // if (this.$store.state.user.userInfo.openid) {
                //     this.$router.push({
                //         name: 'Home'
                //     });
                // } else {
                //     this.$router.push({
                //         name: 'WechatLogin',
                //         params: {
                //             go: true
                //         }
                //     });
                // }
                this.getPoster();
            },
            // 游客登录 随便看看
            noLogin () {
                this.$router.push({
                    name: 'ProductDetails',
                    query: {
                        productId: 64
                    }
                });
            },
            // 微信登录
            wechatLogin () {
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appId}&redirect_uri=${encodeURIComponent('http://www.13255555555.cn/login')}&response_type=code&scope=snsapi_userinfo&state=${config.platformCode}&connect_redirect=1#wechat_redirect`;
            }
        },
        // 表单验证
        validations: {
            formData: {
                mobile: {
                    required,
                    isPoneAvailable
                },
                code: {
                    required
                }
            }
        },
        computed: {
            ...mapGetters('system', ['getTimer'])
        },
        destroyed () {
            clearInterval(this.interval);
        }
    };
</script>

<style scoped lang="scss">
.login-page{
    background-image: url("../../assets/images/login_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.logo-img{
    width: 164px;
    height: 164px;
    //overflow: hidden;
    margin: 240px auto 0;
    border-radius: 50%;
    border: 6Px solid white;
    img{
        width: 100%;
        height: 100%;
    }
}
.login-form{
    width: 85%;
    border-radius: 24px;
    margin: 40px auto 0;
    background-color: white;
    padding: 50px 40px 120px 40px;
    position: relative;
    .form-title{
        font-weight: bold;
        font-size: 34px;
    }
    .form-box{
        margin-top: 60px;
        .getCode{
            padding: 12px 18px;
            border: 1px solid $main_color;
            color: $main_color;
            border-radius: 16px;
        }
        .field-item{
            margin-bottom: 16px;
            border-bottom: 1px solid #eee;
            padding-bottom: 12px;
        }
    }
    .button-box{
        position: absolute;
        bottom: -22Px;
        width: 68%;
        left: 0;
        right: 0;
        margin: auto;
        box-shadow: 0 0 20px $main_color;
        border-radius: 2em;
    }
}

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        width: 100vw;
        font-size: 14px;
        .content {
            padding: 0 20px;
            width: 70vw;
            background: white;
            height: 900px;
            margin-bottom: 20px;
            overflow-y: auto;

            .modal-rich-txt {
                margin-top: 20px;
                overflow-y: scroll;
                height: 520px;
            }

            .check-box {
                margin-top: 10px;
                padding: 20px;
            }

            .button-sunbmit {
                @include flexCenter;

                .button-sunbmit-btn {
                    height: 68px;
                    width: 180px;
                }
            }
        }

        .close {
            margin-top: 24px;
            color: #fff;
            height: 48px;
            width: 48px;
            @include flexCenter;
            border-radius: 50%;
            border: 1px solid #fff;
            font-size: 34px;
        }

        .poster {
            width: 80%;

            img {
                width: 100%;
            }
        }
    }
</style>
