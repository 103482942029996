<template>
    <div>
        <div class="bg">
            <p>{{userInfo.name}}的钱包</p>
            <div class="card">
                <div class="top-row">
                    <div class="total">可提现余额</div>
                    <div class="list-icon" @click="$router.push({name: 'CardList'})">
                        <van-icon name="debit-pay" />
                        <span>卡片列表</span>
                    </div>
                </div>
                <div class="score-value">
                    <van-tag type="primary" size="large" color="#3a6ae4FF">积分</van-tag>
                    <div class="remain-amount">{{money.remainAmount}}</div>
                </div>
                <div class="withdraw-button">
                    <van-button type="primary" color="#3a6ae4FF" round @click="$router.push({name: 'WithdrawPage'})">立即提现</van-button>
                    <div class="red">可提现积分必须大于10</div>
                </div>
            </div>
        </div>
        <div class="withdraw-record">
            <div class="title">提现记录</div>
            <template v-if="list.length > 0">
                <div v-for="item in list" :key="item.id" class="record_list">
                    <div class="record_row">
                        <span class="record_title">支付时间: </span>
                        <span class="record_value">{{item.createTime}}</span>
                    </div>
                    <div class="record_row">
                        <span class="record_title">提现方式: </span>
                        <span class="record_value">{{item.type}}</span>
                    </div>
                    <div class="record_row">
                        <span class="record_title">提现积分: </span>
                        <span class="record_value">{{item.amount}}</span>
                    </div>
                    <div class="status">
                        <img src="../../assets/images/progressing.png" v-if="item.auditState == '0'">
                        <img src="../../assets/images/transform.png" v-if="item.auditState == '1'">
                        <img src="../../assets/images/ok.png" v-if="item.auditState == '2'">
                    </div>
                </div>
            </template>
            <div v-else>
                <van-divider>没有更多了</van-divider>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'WithdrawHome',
        computed: {
            ...mapState('user', ['userInfo'])
        },
        data () {
            return {
                money: {},
                loading: false,
                finished: false,
                list: []
            };
        },
        mounted () {
            this.$axios.get(this.$requestUrl.home.getUserAccount).then(res => {
                this.money = res.data;
            });
            this.$axios.get(this.$requestUrl.home.queryCashOutList).then(res => {
                this.list = res.data;
            });
        },
        methods: {}
    };
</script>

<style scoped lang="scss">
.bg{
    height: 340px;
    background-color: $main_color;
    position: relative;
    padding: 30px 60px;
    &>p{
        font-size: $title_font_size;
        font-weight: bold;
        color: #fff;
        margin-top: 20px;
    }
    .card{
        position: absolute;
        border-radius: 18px;
        background-color: rgba(255, 255, 255, 0.8);
        height: 380px;
        left: 60px;
        right: 60px;
        bottom: -140px;
        box-shadow: 0 5px 20px rgb(212, 212, 212);
        padding: 40px;
        box-sizing: border-box;
        .top-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .total{
                font-size: $weight_font_size;
            }
            .list-icon{
                font-size: $normal_font_size;
                color: $main_color;
                @include flexCenter;
                span{
                    padding-left: 10px;
                }
                i{
                    font-size: 38px;
                }
            }
        }
        .score-value{
            margin-top: 42px;
            display: flex;
            align-items: center;
            .remain-amount{
                padding-left: 18px;
                font-weight: bold;
                font-size: 68px;
            }
        }
        .withdraw-button{
            margin-top: 24px;
            button{
                width: 100%;
                height: 72px;
                margin-bottom: 16px;
            }
        }
    }
}
.withdraw-record{
    background-color: #fff;
    margin-top: 190px;
    padding: 24px 40px;
    min-height: calc(100vh - 720px);
    .title{
        border-left: 8px solid $main_color;
        font-size: $weight_font_size;
        padding-left: 24px;
        font-weight: bold;
        margin-bottom: 24px;
    }
}
.record_list{
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 28px;
    border-radius: 0.2em;
    border: 1px solid rgb(201, 201, 201);
    padding: 24px;
    box-shadow: 0 5px 10px rgb(223, 223, 223);
    font-size: 24px;
    flex-direction: column;
    background-color: white;
}
/* .record_title{
  color: gray;
} */
.record_value{
    color: gray;
}
.record_row{
    margin-bottom: 12px;
}
.record_row:last-child{
    margin-bottom: 0;
}
.status{
    position: absolute;
    right: 0;
    top: 0;
}
.status img{
    width: 120px;
    height: 120px;
}
.no_remark{
    color: gray;
    text-align: center;
}
</style>
