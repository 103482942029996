import axios from 'axios';
import store from '@/store/index';
import router from '../router'; // 引入router
import ipConfig from '@/api/ip_config';
// 创建一个实例
const instance = axios.create({
    baseURL: ipConfig.baseUrl,
    timeout: 6000
});

// http request 拦截器
instance.interceptors.request.use(
    config => {
        config.headers = {
            ...config.headers,
            Authorization: store.state.user.userInfo.token // 设置Token
        };
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
instance.interceptors.response.use(
    response => {
        // 拦截响应，做统一处理
        if (response.data.code) {
            switch (response.data.code) {
                case '103':
                    store.commit('user/setUserInfo', {});
                    router.replace({
                        name: 'Login',
                        query: {
                            ...router.currentRoute.query
                        }
                    });
                    break;
                case '104':
                    store.commit('user/setUserInfo', {});
                    router.replace({
                        name: 'Login',
                        query: {
                            ...router.currentRoute.query
                        }
                    });
                    break;
                case 200:
                    return response.data;
            }
        }
        return response.data;
    },
    // 接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response.status); // 返回接口返回的错误信息
    }
);

export default instance;
