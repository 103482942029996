import config from './config';

// 是否为空 排除0
const isEmpty = {
    methods: {
        isEmpty: function (val) {
            if (val === null || val === undefined || val === '') {
                return true;
            }
            return false;
        }
    }
};

// 预览富文本中的图片
const previewImages = {
    methods: {
        previewImages (e) {
            if (e.target.src) {
                const srcList = Array.from(e.currentTarget.getElementsByTagName('img')).map(i => i.src);
                const index = srcList.indexOf(e.target.src);
                this.$imagePreview(srcList, index);
            }
        }
    }
};

export default {
    isEmpty,
    previewImages
};
