<template>
  <div class="tabbar">
      <van-tabbar v-model="active" active-color="#000" inactive-color="#686868">
          <van-tabbar-item replace to="/" icon="home-o" name="Home">
              <span>首页</span>
              <template #icon="props">
                  <img :src="props.active ? require('@/assets/images/home_tab_active.png') : require('@/assets/images/home_tab_inactive.png')" />
              </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/personal_center" icon="search" name="PersonalCenter" v-if="$store.state.user.userInfo.isAllOpen !== 0">
              个人中心
              <template #icon="props">
                  <img :src="props.active ? require('@/assets/images/personal_center_icon_active.png') : require('@/assets/images/personal_center_icon_inactive.png')" />
              </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/product_center" icon="friends-o" name="ProductCenter">
              福利商城
              <template #icon="props">
                  <img :src="props.active ? require('@/assets/images/store_icon_active.png') : require('@/assets/images/store_icon_inactive.png')" />
              </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/helper" icon="setting-o" name="Helper" v-if="$store.state.user.userInfo.isAllOpen !== 0">
              帮助中心
              <template #icon="props">
                  <img :src="props.active ? require('@/assets/images/helper_icon_active.png') : require('@/assets/images/helper_icon_inactive.png')" />
              </template>
          </van-tabbar-item>
      </van-tabbar>
  </div>
</template>

<script>
    export default {
        name: 'Tabbar',
        data () {
            return {
                active: 'Home'
            };
        },
        mounted () {
            this.active = this.$route.name;
        },
        watch: {
            $route (val) {
                this.active = val.name;
            }
        },
        methods: {
            tabSwitch1: function (value, index) {
                console.log(index);
            }
        }
    };
</script>

<style scoped lang="scss">
.tabbar{
  //height: $tab_height;
}
</style>
