<template>
    <div class="nav-box">
        <van-nav-bar
            @click-left="onClickLeft"
            class="vsj-nav"
        >
            <template #left>
                <div class="back-box" v-if="getLeftNavType === 1">
                    <van-icon name="arrow-left" />
                </div>
            </template>
            <template #title>
                <span class="title-font">
                    {{getAppTitle}}
                </span>
            </template>
        </van-nav-bar>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';

    export default {
        name: 'Navbar',
        data () {
            return {};
        },
        methods: {
            onClickLeft () {
                if (this.getLeftNavType === 1) {
                    this.$router.go(-1);
                } else {}
            },
            onClickRight () {
                alert('header头部， 点击title');
            },
            more () {
                alert('header头部， 点击更多');
            }
        },
        computed: {
            ...mapGetters(['getAppTitle', 'getLeftNavType'])
        }
    };
</script>

<style scoped lang="scss">
.location-box{
    display: flex;
    align-items: center;
    .location{
        color: $main_color;
        font-size: $weight_font_size;
    }
    .location-img{
        width: 48px;
        height: 48px;
        margin-right: 11px;
    }
}

.title-font{
    font-size: $title_font_size;
}
.nav-box{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background: #fff;
    right: 0;
    height: $nav_height;
    .vsj-nav{
        height: $nav_height;
    }
    ::v-deep .van-nav-bar__content{
        height: $nav_height;
        background-color: $main_color;
        .title-font{
            color: #fff;
        }
    }
}
.back-box{
    @include flexCenter;
    .van-icon{
        color: #fff;
    }
}
</style>
