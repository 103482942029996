<template>
  <div id="app">
    <keep-alive>
      <Navbar v-show="getShowNav"></Navbar>
    </keep-alive>
    <div class="content" :class="{'mt-nav': getShowNav, 'mb-tab': getShowTabbar}">
      <router-view/>
    </div>
    <keep-alive>
      <Tabbar v-show="getShowTabbar"></Tabbar>
    </keep-alive>
  </div>
</template>

<script>
    import Navbar from '@/components/common/Navbar';
    import Tabbar from '@/components/common/Tabbar';
    import { mapGetters, mapState, mapActions } from 'vuex';
    export default {
        name: 'App',
        components: {
            Navbar,
            Tabbar
        },
        mounted () {
            if (this.userInfo.token) {
                this.getPoster();
                this.getPreLoad();
            }
        },
        data () {
            return {};
        },
        methods: {
            ...mapActions(['getPoster', 'getPreLoad'])
        },
        computed: {
            ...mapGetters(['getShowNav', 'getShowTabbar']),
            ...mapState('user', ['userInfo'])
        }
    };
</script>

<style lang="scss" scoped>
.mt-nav{
    margin-top: $nav_height;
}
.mb-tab{
    margin-bottom: 100px;
}
</style>
