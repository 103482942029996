<template>
    <div>
        <div class="gwn-tabs">
            <van-tabs :ellipsis="false" @change="changeIndex" v-model="active">
                <van-tab v-for="item in productCategory" :title="item.name" :key="item.id" :name="item.id">
                    <div class="product-content">
                        <van-list
                            v-if="loaded"
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                            :immediate-check="false"
                        >
                            <ul>
                                <li v-for="product in productList" :key="product.id" class="product-li">
                                    <img :src="product.productImage" alt="">
                                    <div class="content">
                                        <div class="product-title">
                                            {{ product.name }}
                                        </div>
                                        <div class="real-price">市场价: {{ product.price }}</div>
                                        <div class="my-price">兑换价: {{ product.integral }}</div>
                                        <div class="button-box">
                                            <van-button block type="info" class="apply-button" @click="goBuy(product)" v-if="Number(product.prodcutType) >= 3" :disabled="product.permission !== 1">
                                                <template v-if="product.permission === 0">
                                                    待首刷
                                                </template>
                                                <template v-if="product.permission === 1">
                                                    兑换首刷礼
                                                </template>
                                                <template v-if="product.permission === 2">
                                                    已兑换
                                                </template>
                                            </van-button>
                                            <van-button block type="info" class="apply-button" @click="goBuy(product)" v-else>
                                                立即兑换
                                            </van-button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </van-list>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductCenter',
        data () {
            return {
                productCategory: [],
                active: null,
                productList: [],
                finished: false,
                loaded: false,
                loading: false,
                params: {
                    pageNum: 1,
                    pageSize: 10
                }
            };
        },
        mounted () {
            const {
                $axios,
                $requestUrl
            } = this;
            $axios.get(
                $requestUrl.product.getProductCategoryList
            ).then(res => {
                this.productCategory = this.productCategory.concat(res.data);
                this.loaded = true;
                if (this.productCategory.length > 0) {
                    this.active = this.productCategory[0].id;
                }
                this.onLoad();
            });
        },
        methods: {
            changeIndex (index) {
                this.finished = true;
                this.params.pageNum = 1;
                this.finished = false;
                this.productList = [];
                this.onLoad();
            },
            onLoad () {
                const {
                    $axios,
                    $requestUrl
                } = this;
                this.loading = true;
                $axios.get($requestUrl.product.getProductList, {
                    params: {
                        ...this.params,
                        category: this.active === 0 ? null : this.active
                    }
                }).then(res => {
                    this.productList = this.productList.concat(res.data.records);
                    // 数据全部加载完成
                    if (this.productList.length >= res.data.total) {
                        this.finished = true;
                    } else {
                        this.params.pageNum += 1;
                    }
                }).catch(() => {
                    this.$toast.fail('加载商品出错');
                    this.finished = true;
                }).finally(() => {
                    this.loading = false;
                });
            },
            goBuy (item) {
                this.$router.push({
                    name: 'ProductDetails',
                    query: {
                        productId: item.id
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.gwn-tabs {
    //background-color: #fff;
    .product-content {
        padding: 40px;

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .product-li {
            width: 48%;
            border-radius: 12px;
            overflow: hidden;
            margin-bottom: 24px;

            img {
                display: block;
                width: 100%;
                height: 240px;
            }

            .content {
                background-color: #fff;
                padding: 16px;

                .product-title {
                    @include ellipsisBasic(2);
                    font-size: 32px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    height: 96px;
                }

                .real-price {
                    color: #999999;
                    text-decoration: line-through;
                    margin-bottom: 4px;
                }

                .my-price {
                    color: #fd9d22;
                }

                .my-price, .real-price {
                    font-size: 28px;
                }
            }

            .button-box {
                padding: 20px 0 20px;

                .apply-button {
                    font-weight: bold;
                    border-radius: 8px;
                    background-color: #3a6ae4;
                    border: none;
                    height: 74px;
                }
            }
        }
    }
}

::v-deep .van-tabs__wrap {
    background-color: #fff;
}
</style>
