<template>
    <div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="formData.name"
                name="name"
                label="姓名"
                placeholder="请输入姓名"
            />
            <van-field
                v-model="formData.idCard"
                name="idCard"
                label="身份证号"
                placeholder="请输入身份证号"
            />
            <van-field
                v-model="formData.backCard"
                name="backCard"
                label="银行卡号"
                placeholder="请输入银行卡号"
            />
            <van-field
                v-model="formData.mobile"
                name="mobile"
                label="预留手机号"
                placeholder="请输入预留手机号"
            />
            <van-field name="bankCardFront" label="身份证正面">
                <template #input>
                    <van-uploader v-model="bankCardFront" :max-count="1" :after-read="upload1" :before-read="beforeRead"/>
                </template>
            </van-field>
            <van-field name="bankCardBack" label="身份证反面">
                <template #input>
                    <van-uploader v-model="bankCardBack" :max-count="1" :after-read="upload2" :before-read="beforeRead"/>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" color="#3a6ae4FF" style="background-color: #3a6ae4">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    function phoneFun (phones) {
        var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!myreg.test(phones)) {
            console.log('手机号格式不正确');
            return false;
        } else {
            console.log('手机号格式正确');
            return true;
        }
    }
    export default {
        name: 'Identity',
        data () {
            return {
                bankCardBack: [],
                bankCardFront: [],
                formData: {
                    name: '',
                    backCard: '',
                    bankCardFront: '',
                    mobile: '',
                    bankCardBack: '',
                    idCard: ''
                }
            };
        },
        methods: {
            upload1 (v) {
                console.log(v, 'vvvv');
                this.$toast.loading({
                    message: '上传中...',
                    duration: 0,
                    forbidClick: true
                });
                const params = new FormData();
                params.append('file', v.file);
                this.$axios.post(this.$requestUrl.home.uploadIdCard + '?type=1', params, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(res => {
                    if (res.code !== '101') {
                        this.$toast(res.message);
                    } else {
                        this.formData.bankCardFront = res.data;
                    }
                }).catch(e => {
                    this.$toast('身份证正面上传失败');
                }).finally(() => {
                    this.$toast.clear();
                });
            },
            upload2 (v) {
                console.log(v, 'vvvv');
                this.$toast.loading({
                    message: '上传中...',
                    duration: 0,
                    forbidClick: true
                });
                const params2 = new FormData();
                params2.append('file', v.file);
                this.$axios.post(this.$requestUrl.home.uploadIdCard + '?type=2', params2, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(res => {
                    if (res.code !== '101') {
                        this.$toast(res.message);
                    } else {
                        this.formData.bankCardBack = res.data;
                    }
                }).catch(e => {
                    this.$toast('身份证背面上传失败');
                }).finally(() => {
                    this.$toast.clear();
                });
            },
            beforeRead(file, detail){
                 if (file.size > 10 * 1024 * 1024) {
                     this.$toast('文件大小不能超过 10M');
                     return false;
                  }
                   return true;
            },
            onSubmit (v) {
                if (!v.mobile) {
                    this.$toast('手机号不能为空');
                    return;
                }
                if (!v.name) {
                    this.$toast('姓名不能为空');
                    return;
                }

                if (!phoneFun(v.mobile)) {
                    this.$toast('手机号码有误');
                    return false;
                }

                if (!v.backCard) {
                    this.$toast('银行卡号不能为空');
                    return;
                }

                if (!v.idCard) {
                    this.$toast('身份证号不能为空');
                    return;
                }

                if (!this.formData.bankCardFront) {
                    this.$toast('身份证正面不能为空');
                    return;
                }

                if (!this.formData.bankCardBack) {
                    this.$toast('身份证背面不能为空');
                    return;
                }
                this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true
                });
                this.$axios.post(this.$requestUrl.home.userSign, {
                    bankCardBack: this.formData.bankCardBack,
                    bankCardFront: this.formData.bankCardFront,
                    idCard: this.formData.idCard,
                    mobile: this.formData.mobile,
                    payeeAcc: this.formData.backCard,
                    payeeName: this.formData.name,
                    type: 0
                }).then(async res => {
                    this.$toast.clear();
                    if (res.code !== '101') {
                        this.$toast('签约失败');
                        return;
                    }
                    this.$toast('签约成功');
                    this.$store.commit('user/changeSign', 1);
                    const res2 = await this.$axios.post(this.$requestUrl.product.refreshUserData);
                    // 保存用户信息
                    this.$store.commit('user/setUserInfo', res2.data);
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                });
                console.log(v);
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
