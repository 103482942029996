<template>
    <div>
        <div class="help-box">
            <van-collapse v-model="activeName" accordion>
                <van-collapse-item :name="index" v-for="(item, index) in arrdata" :key="index">
                    <template #title>
                        <div class="title-box">
                            <img :src="require('@/assets/images/question.png')" alt="" class="question">
                            <span>{{item.name}}</span>
                        </div>
                    </template>
                    <p v-html="item.content" class="rich-text" @click="previewImages($event)" style="padding: 0;"></p>
                </van-collapse-item>
                <van-collapse-item :name="8">
                    <template #title>
                        <div class="title-box">
                            <img :src="require('@/assets/images/question.png')" alt="" class="question">
                            <span>微信立减金兑换</span>
                        </div>
                    </template>
                    <p class="rich-text" style="padding: 0;">
                        <a href="https://mapa.quancangyun.cn/ydsh-pq-h5-redeem-code" v-show="Number($store.state.orderType) === 1">请点击此链接，立即兑换</a>
                        <div v-show="Number($store.state.orderType) === 2">
                            <wx-open-launch-weapp
                                id="launch-btn2"
                                style="height: 48px;width: 200px;"
                                username="gh_8c218cd6ba53"
                                :path="'markethall/index/index?scene=IgWZv7j9HOEgWk58x7n1g_'"
                            >
                                <script type="text/wxtag-template">
                                    <style>.btn { color: #0000ee;font-size: 12px;width: 200px;height: 48px }</style>
                                    <div class="btn">请点击此链接，立即兑换</div>
                                </script>
                            </wx-open-launch-weapp>
                        </div>
                    </p>
                </van-collapse-item>
            </van-collapse>
        </div>
        <van-overlay :show="show" @click="show = false">
            <div class="wrapper">
                <div class="main_content">
                    <div class="title_row">
                        联系信息
                    </div>
                    <div class="row">
                        <div>
                            <span>客服服务时间9:00 ~ 18:00</span>
                        </div>
                    </div>
                    <img :src="require('@/assets/images/code.jpg')" alt="">
                </div>
            </div>
        </van-overlay>
        <div class="icon-question" @click="showModal">
            <img :src="require('@/assets/images/call-icon.png')" alt="">
        </div>
    </div>
</template>

<script>
    import mixins from '@/common/mixins';
    export default {
        name: 'Helper',
        mixins: [mixins.previewImages],
        data () {
            return {
                activeName: 8,
                show: false,
                arrdata: []
            };
        },
        mounted () {
            this.$axios.get(
                this.$requestUrl.product.getUserTaxRatio
            ).then(res => {
                this.arrdata = [{
                                    id: 1,
                                    name: '操作流程图',
                                    content: '<img src="https://shou.zopsen.com/help/course/q1-1.png" style="display: block">'
                                }, {
                                    id: 2,
                                    name: '如何申请卡片',
                                    content: '点击首页的立即申请按钮或者进入卡片详情页面点击立即申请，经过您授权微信信息和手机号之后即可进入开卡页面。在开卡页面填写相应信息之后完成开卡。<p style="color: red">首刷成功，即奖励指定首刷礼一份。</p><div><br><p>流程如图：</p><img src="https://shou.zopsen.com/help/course/q0-1.png" style="display: block"><br><p>按钮提示：<div style="margin-top: 10px;display: flex;justify-content: space-between"><img src="https://shou.zopsen.com/help/course/q1-2.png" style="display: block;width: 48%"><img src="https://shou.zopsen.com/help/course/q1-3.png" style="display: block;width: 48%"></div></p></div>'

                                }
                               
                ];
            });
            this.$nextTick(() => {
                const btn = document.getElementById('launch-btn2');
                console.log(btn);
                btn.addEventListener('launch', function (e) {
                    console.log('success');
                });
                this.activeName = null;
            });
        },
        methods: {
            showModal () {
                this.show = true;
            },
            goUrl (url) {
                if (url) {
                    window.location.href = url;
                }
            },
            launchWexApp () {}
        },
        computed: {
            orderType () {
                return this.$store.state.orderType;
            }
        }
    };
</script>

<style scoped lang="scss">
.help-box {
    background-color: #fff;
    border-radius: 0.5em;
    overflow: hidden;
    margin: 120px 40px 120px;

    .title-box {
        display: flex;
        align-items: center;

        .question {
            width: 44px;
            height: 44px;
            margin-right: 14px;
        }
    }
}

.icon-question {
    position: fixed;
    right: 20px;
    top: 60vh;

    img {
        width: 117px;
        height: 117px;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .main_content {
        width: 500px;
        background: white;
        padding: 40px;

        .title_row {
            font-size: 34px;
            font-weight: bold;
            border-left: 12px solid $main_color;
            padding-left: 20px;
            margin-bottom: 20px;
        }

        .row {
            width: 100%;
            text-align: center;
            margin-bottom: 12px;

            span {
                color: red;
            }
        }

        img {
            width: 100%;
        }
    }
}
</style>
