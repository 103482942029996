<template>
    <div>
        <van-empty image="error" description="请授权微信信息" v-if="show">
            <van-button round type="danger" class="bottom-button" color="#3a6ae4FF" @click="wechatLogin">微信授权</van-button>
        </van-empty>
        <van-empty image="https://shou.zopsen.com/qrcode.jpg" description="长按二维码关注首卡公众号，并在公众号中进行登录验证" v-if="showQr">
<!--            <van-button round type="danger" class="bottom-button" color="#3a6ae4FF" @click="wechatLogin">微信授权</van-button>-->
        </van-empty>
    </div>
</template>

<script>
    import ipConfig from '../../api/ip_config';

    function getQueryString (name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }
    export default {
        name: 'WechatLogin',
        data () {
            return {
                show: false,
                showQr: false
            };
        },
        mounted () {
            const go = this.$route.params.go;
            if (go) {
                this.wechatLogin();
                return;
            }
            const code = getQueryString('code');
            if (code) {
                console.log('用户已登录', code);
                this.$axios.get(this.$requestUrl.login.getWechatInfor, {
                    params: {
                        code,
                        channel: window.location.href.indexOf('https://') !== -1 ? 'https' : 'http'
                    }
                }).then(res => {
                    if (res.code === '101') {
                        this.$store.commit('user/setUserInfoWechat', res.data);
                        this.$router.replace({
                            name: 'Home'
                        });
                    } else if (res.code === '102') {
                        // window.location.href = 'http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=MzkyMDI0MTk4NQ==#wechat_redirect';
                        this.showQr = true;
                    } else {
                        this.$toast(res.message);
                    }
                    console.log(res, '用户信息');
                }).catch(() => {
                    this.$toast('登录出错');
                });
            } else {
                this.show = true;
            }
        },
        methods: {
            wechatLogin () {
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7c9968a07258eb63&redirect_uri=${encodeURIComponent('https://xin.zopsen.com/#/wechat_login')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            }
        }
    };
</script>

<style scoped lang="scss">
.bottom-button{
    height: 74px;
    width: 240px;
}
</style>
