<template>
    <div class="list">
        <div class="button-box" @click="show = true"><div class="apply-button">申请首刷礼</div></div>
       <table>
            <tr>
                <th>申请人</th>
                <th>手机号</th>
                <th>首刷礼</th>
                <th>状态</th>
                <th>申请时间</th>
            </tr>
            <tr v-for="item in list" :key="item.id" class="tr">
                <td>{{item.name}}</td>
                <td>{{item.mobile}}</td>
                <td>{{item.couponName}}</td>
                <td>
                    <span class="status">{{item.stateStr}}</span>
                </td>
                <td>{{item.applicationTimeStr}}</td>
            </tr>
        </table>
        <van-overlay :show="show" @click="show = false">
            <div class="wrapper application-box" @click.stop>
                <div class="form-box">
                    <van-form @submit="onSubmit">
                        <van-field
                            v-model="username"
                            name="username"
                            label="用户姓名"
                            type="text"
                            maxlength=20
                            placeholder="请输入用户姓名"
                        />
                        <van-field
                            v-model="userTel"
                            name="userTel"
                            label="手机号"
                            type="tel"
                            placeholder="默认为注册手机号"
                        />
                        <div class="submit-button">
                            <van-button round block type="info" native-type="submit" size="small" class="submit-button-real">提交</van-button>
                            <div @click="show = false" style="margin-left:30px;color: gray;" >取消</div>
                        </div>
                    </van-form>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {isPoneAvailable} from "../../common/utils";
    export default {
        name: 'CouponRecord',
        data () {
            return {
                list: [],
                userTel: '',
                username: '',
                show: false
            };
        },
        mounted () {
            this.loadData();
        },
        methods: {
            loadData(){
                this.$axios.get(this.$requestUrl.product.getUsercouponRecordList).then(res => {
                    this.list = res.data;
                });
            },
            onSubmit () {
                if (this.userTel!="" && !isPoneAvailable(this.userTel)) {
                    this.$toast('请填写正确的手机号');
                    return;
                }
                if (!this.username) {
                    this.$toast('请填写用户名称');
                    return;
                }
                const {
                    $axios,
                    $requestUrl
                } = this;
                this.$toast.loading({
                    message: '加载中',
                    forbidClick: true
                });
                $axios.get($requestUrl.product.applicationCounponRecord, {
                    params: {
                        mobile: this.userTel,
                        realname: this.username
                    }
                }).then(res => {
                    if (res.code !== '101') {
                        this.$toast(res.message);
                    } else {
                        this.$toast('发放成功');
                        this.userTel = '';
                        this.show = false;
                        this.loadData();
                    }
                }).catch(() => {
                    this.$toast('发放失败');
                }).finally(() => {
                    //this.$toast.clear();
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.list{
    padding: 20px 30px;
    table{
        width: 100%;
        background-color: #fff;
        border-radius: 16px;
        tr{
            text-align: center;
            border-collapse: collapse;
            border-bottom: 1px solid #c7c7c7;
            th{
                line-height: 74px;
                font-size: 28px;
            }
        }
        .tr{
            td{
                line-height: 74px;
                font-size: 26px;
            }
            //text-align: center;
            .status{
                color: #fd9d22;
            }
        }
    }
}
 .button-box {
            display: flex;
            margin-top: 24px;
            margin-bottom: 24px;
            justify-content: end;
            .apply-button {
                display: flex;
                font-weight: bold;
                border-radius: 8px;
                background-color: rgb(164, 64, 222);
                border: none;
                height: 50px;
                width: 120px;
                color: #fff;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
    }
   .application-box {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
   }
   .submit-button{
       display: flex;
       align-items: center;
       justify-content: center;
       padding-top: 44px;
       .submit-button-real{
           max-width: 480px;
           background-color: $main_color;
           border-color: $main_color;
       }
   }
   .form-box{
       position: absolute;
       left: 0;
       right: 0;
       bottom: 0;
       top: 0;
       margin: auto;
       background-color: #fff;
       width: 90%;
       height: 250px;
       padding: 48px 0;
       border-radius: 15px;
   }
</style>
