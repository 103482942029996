<template>
    <div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="formData.name"
                name="name"
                label="姓名"
                placeholder="姓名"
                readonly
                :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
                v-model="formData.mobile"
                name="mobile"
                label="预留手机号"
                type="tel"
                readonly
                placeholder="预留手机号"
                :rules="[{ required: true, message: '请填写预留手机号' }]"
            />
            <van-field
                v-model="formData.bankCard"
                name="bankCard"
                label="银行卡号"
                placeholder="请输入银行卡号"
                :rules="[{ required: true, message: '请填写银行卡号' }]"
            />
            <van-field
                v-model="formData.idCard"
                name="idCard"
                readonly
                label="身份证号"
                placeholder="请输入身份证号"
                :rules="[{ required: true, message: '请填写身份证号' }]"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" color="#3a6ae4FF" native-type="submit" v-if="!id">提交</van-button>
                <van-button round block type="danger" native-type="button" v-if="id" @click="deleteThis">删除</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    export default {
        name: 'AddWithdraw',
        data () {
            return {
                formData: {
                    name: '',
                    idCard: '',
                    mobile: '',
                    bankCard: ''
                },
                id: null
            };
        },
        mounted () {
            const id = this.$route.query.id;
            if (id) {
                this.id = id;
                this.$axios.get(this.$requestUrl.home.queryUserAccount, {
                    params: {
                        id
                    }
                }).then(res => {
                    this.formData.name = res.data.payeeName;
                    this.formData.idCard = res.data.idCard;
                    this.formData.mobile = res.data.mobile;
                    this.formData.bankCard = res.data.payeeAcc;
                });
            } else {
                this.$axios.post(this.$requestUrl.home.getUserSignInfor).then(res => {
                    this.formData.name = res.data.payeeName;
                    this.formData.idCard = res.data.idCard;
                    this.formData.mobile = res.data.mobile;
                });
            }
        },
        methods: {
            onSubmit () {
                this.$axios.post(this.$requestUrl.home.addUserAccount, {
                    idCard: this.formData.idCard,
                    mobile: this.formData.mobile,
                    payeeAcc: this.formData.bankCard,
                    payeeName: this.formData.name,
                    type: 0
                }).then(res => {
                    this.$toast('添加成功');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                });
            },
            deleteThis () {
                this.$axios.post(this.$requestUrl.home.removeUserAccount + '?id=' + this.id).then(res => {
                    this.$toast('删除成功');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                });
            }
        }
    };
</script>

<style scoped>

</style>
