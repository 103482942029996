const user = {
    namespaced: true,
    state: {
        userInfo: {}
    },
    mutations: {
        setUserInfo (state, payload) {
            state.userInfo = payload;
        },
        setUserInfoWechat (state, payload) {
            state.userInfo = payload;
        },
        changeUserType (state, payload) {
            state.userInfo.type = payload;
        },
        changeUserAgree (state, payload) {
            state.userInfo.isAgree = payload;
        },
        changeSign (state, payload) {
            state.userInfo.issign = payload;
        }
    },
    actions: {},
    getters: {
        isLogin: state => {
            if (state.userInfo.token) {
                return true;
            }
            return false;
        },
        isPartner: state => {
            if (state.userInfo.type === 3) {
                return true;
            }
            return false;
        },
        // 是否微信登录
        isWxLogin: state => {
            if (state.userInfo.openid) {
                return true;
            }
            return false;
        },
        getUserId: state => {
            return state.userInfo.id || null;
        }
    }
};

export default {
    moduleName: 'user',
    module: user
};
