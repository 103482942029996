const address = {
    namespaced: true,
    state: {
        list: [
            {
                name: '李小武',
                sex: 2,
                telephone: 13899999999,
                defaultAddress: true,
                addressDetail: '山东省青岛市市北区龙城路31号 卓越·世纪中心'
            }
        ],
        loading: false,
        finished: false,
        refreshing: false,
        vuexx: 'vuexxxxxxxx'
    },
    mutations: {
        setList (state, value) {
            state.list = value;
        },
        setName (state, object) {
            const { id, name } = object;
            state.list[id].name = name;
        },
        setSex (state, object) {
           const { id, sex } = object;
           state.list[id].sex = sex;
        },
        setTelephone (state, object) {
            const { id, telephone } = object;
            state.list[id].telephone = telephone;
         },
         setDefaultAddress (state, object) {
            const { id, defaultAddress } = object;
            state.list[id].defaultAddress = defaultAddress;
         },
         setAddressDetail (state, object) {
            const { id, addressDetail } = object;
            state.list[id].addressDetail = addressDetail;
         }
    },
    actions: {
        onLoad (context) { // 异步请求数据
            setTimeout(() => {
                if (context.state.refreshing) {
                    context.state.list = [];
                    context.state.refreshing = false;
                }

                for (let i = 0; i < 10; i++) {
                    context.state.list = [...context.state.list, {
                        name: '李小武',
                        sex: 2,
                        telephone: 13899999999,
                        defaultAddress: true,
                        addressDetail: '山东省青岛市市北区龙城路31号 卓越·世纪中心'
                    }];
                }
                context.state.loading = false;

                if (context.state.list.length >= 40) {
                    context.state.finished = true;
                }
            }, 1000);
            context.commit('setList', context.state.list);
            console.log(context.state.loading, context.state.finished, context.state.refreshing);
        },
        onRefresh (context) {
            // 清空列表数据
          context.state.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
          context.state.loading = true;
          context.dispatch('onLoad');// 执行actions中的onload
        }
    },
    getters: { // 计算属性
        getList (state) {
            return state.list;
        }
    }
};
export default {
    moduleName: 'address',
    module: address
  };
