<template>
    <div>
        <van-form @submit="onSubmit">
            <van-field
                readonly
                clickable
                name="area"
                :value="area"
                label="地区选择"
                placeholder="点击选择省市区"
                @click="showArea = true"
            />
            <van-popup v-model="showArea" position="bottom">
                <van-area
                    :area-list="areaList"
                    @confirm="onConfirm"
                    @cancel="showArea = false"
                />
            </van-popup>
            <van-field
                v-model="formData.name"
                name="name"
                label="收货人"
                placeholder="收货人"
            />
            <van-field
                v-model="formData.mobile"
                name="mobile"
                label="收货人电话"
                placeholder="收货人电话"
                type="tel"
            />
            <van-field
                label="详细地址"
                type="textarea"
                v-model="formData.addressDetails"
                name="addressDetails"
                rows="3"
                placeholder="详细地址"
            />
            <van-field name="radio" label="默认地址">
                <template #input>
                    <van-radio-group v-model="formData.isDefault" direction="horizontal">
                        <van-radio name="1">默认</van-radio>
                        <van-radio name="2">非默认</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" color="#3a6ae4FF">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
// 判断是否为手机号的正则表达式
    import { areaList } from '@vant/area-data';
    function phoneFun (phones) {
        var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!myreg.test(phones)) {
            console.log('手机号格式不正确');
            return false;
        } else {
            console.log('手机号格式正确');
            return true;
        }
    }
    export default {
        name: 'CreateAddress',
        data () {
            return {
                areaList,
                showArea: false,
                area: null,
                formData: {
                    area: null,
                    mobile: null,
                    isDefault: '1',
                    addressDetails: null,
                    name: ''
                }
            };
        },
        mounted () {
            const addrId = this.$route.query.addressId;
            if (addrId) {
                this.$axios.get(this.$requestUrl.product.getUserAdress, {
                    params: {
                        id: addrId
                    }
                }).then(res => {
                    const data = res.data;
                    this.area = `${data.province},${data.city},${data.region}`;
                    const array = [];
                    for (const dataKey in areaList.province_list) {
                        if (areaList.province_list[dataKey] === data.province) {
                            array.push({
                                name: areaList.province_list[dataKey],
                                code: dataKey
                            });
                            break;
                        }
                    }
                    for (const dataKey in areaList.city_list) {
                        if (areaList.city_list[dataKey] === data.city) {
                            array.push({
                                name: areaList.city_list[dataKey],
                                code: dataKey
                            });
                            break;
                        }
                    }
                    for (const dataKey in areaList.county_list) {
                        if (areaList.county_list[dataKey] === data.region) {
                            array.push({
                                name: areaList.county_list[dataKey],
                                code: dataKey
                            });
                            break;
                        }
                    }

                    this.formData.area = array;
                    this.formData.name = data.name;
                    this.formData.mobile = data.mobile;
                    this.formData.addressDetails = data.detailAddress;
                    this.formData.isDefalut = data.isDefalut;
                });
            }
        },
        methods: {
            onSubmit (v) {
                console.log(v);
                if (!v.area) {
                    this.$toast('地址不能为空');
                    return;
                }
                if (!v.mobile) {
                    this.$toast('手机号不能为空');
                    return;
                }
                if (!v.name) {
                    this.$toast('收货人姓名不能为空');
                    return;
                }

                if (!phoneFun(v.mobile)) {
                    this.$toast('手机号码有误');
                    return false;
                }

                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: '加载中...',
                    forbidClick: true
                });
                this.$axios.post(this.$route.query.addressId ? this.$requestUrl.product.updateUserAdress : this.$requestUrl.product.addUserAdress, {
                    id: this.$route.query.addressId || null,
                    city: this.formData.area[1].name,
                    detailAddress: this.formData.addressDetails,
                    isDefalut: Number(this.isDefalut),
                    mobile: this.formData.mobile,
                    name: this.formData.name,
                    province: this.formData.area[0].name,
                    region: this.formData.area[2].name
                }).then(res => {
                    this.$toast.clear();
                    this.$toast('添加成功');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                });
            },
            onConfirm (v) {
                console.log(v);
                this.area = v.map(i => i.name).join(',');
                this.formData.area = v;
                this.showArea = false;
            }
        }
    };
</script>

<style scoped lang="scss">
::v-deep .van-radio__icon--checked .van-icon{
    background-color: $main_color;
    border: 1px solid $main_color;
}
</style>
