<template>
    <div>
        <div class="card-img">
            <div>
                <img :src="card.image" alt="">
                <span class="name">{{card.name}}</span>
            </div>
        </div>
        <div class="tabs-box">
            <van-tabs v-model="active">
                <van-tab title="卡片简介">
                    <p v-html="card.introduction" class="desc"></p>
                </van-tab>
                <van-tab title="特色功能">
                    <p v-html="card.feature" class="desc"></p>
                </van-tab>
                <van-tab title="温馨提示">
                    <p v-html="card.prompt" class="desc"></p>
                </van-tab>
            </van-tabs>
        </div>
        <div class="button-box">
            <van-button block type="info" class="apply-button" @click="goHref(item)">立即申请</van-button>
        </div>
        <!--    申请协议    -->
        <van-overlay :show="show2" @click="show2 = false" :lock-scroll="false">
            <div class="wrapper" @click.stop>
                <div class="content">
                    <van-tabs v-model="tabIndex2" :ellipsis="false" v-if="show2">
                        <van-tab title="用户协议" name="userRole">
                            <div class="rich-text modal-rich-txt">
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">【服务消费者】用户协议</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">特别提示：</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">在使用</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山管理咨询有限公司</span><span style="font-family:&#39;宋体&#39;">（以下简称</span><span
                                    style="font-family:Calibri">“</span><span style="font-family:&#39;宋体&#39;">北京首山</span><span
                                    style="font-family:Calibri">”</span><span style="font-family:&#39;宋体&#39;">）及关联公司提供的各项服务之前，请</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">不同意本服务协议及</span><span
                                    style="font-family:Calibri">/</span><span style="font-family:&#39;宋体&#39;">或随时对其的修改，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应立即停止注册；</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">一旦使用</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山</span><span style="font-family:&#39;宋体&#39;">提供的服务，即视为</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">已了解并完全同意本服务协议各项内容，包括北京首山对服务协议所做的修改，并成为我们的用户。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">北京首山管理咨询有限公司作为中国光大银行有限公司厦门分行（以下简称“光大银行”）数字化营销获客业务代理商，为光大银行推广宣传光大信用卡业务，在此郑重提示您：</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您注册成功后将接受光大银行的审核，光大银行有权拒绝及随时停用您的注册账户，</span><span
                                    style="font-family:&#39;宋体&#39;">在您通过银行审核之前，将无法使用获得的积分，通过银行审核后方可正常使用获得的积分，在您被银行停用后，无法获得新的积分，之前累计的积分可正常使用，</span><span
                                    style="font-family:&#39;宋体&#39;">您介绍的光大信用卡客户应遵守光大银行的以下规则，为光大银行介绍优质客群：</span></p>
                                <table cellspacing="0" cellpadding="0" style="width:100%; border-collapse:collapse">
                                    <tbody>
                                    <tr style="height:27pt">
                                        <td style="width:9.96%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                        <td style="width:37.88%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle; background-color:#e2efda">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">优质客群</span></p></td>
                                        <td style="width:52.16%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle; background-color:#fce4d6">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">禁止类</span></p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">年龄</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">25-35岁</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">学历</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">本科、大专</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">社保</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">3月以上</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">公积金</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">3月以上</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">婚姻</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">已婚</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">房产</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">有房</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                    </tr>
                                    <tr style="height:24pt">
                                        <td rowspan="6"
                                            style="width:9.96%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;; font-weight:bold">职业</span></p></td>
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">一、机关事业单位公务人员</span>
                                            </p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">一、娱乐休闲业从业人员，包括卡拉</span><span
                                                style="font-family:&#39;宋体&#39;">OK、夜总会、网吧、游戏机中心、洗浴中心等；</span></p></td>
                                    </tr>
                                    <tr style="height:97.5pt">
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">二、企业白领</span><br><span
                                                style="font-family:&#39;宋体&#39;">1.金融、电力、烟草、石化、航空、地铁、自来水、能源、电信和邮政等垄断行业</span><br><span
                                                style="font-family:&#39;宋体&#39;">2.全球/国内500强/上市企业</span><br><span
                                                style="font-family:&#39;宋体&#39;">3.国有企业</span><br><span style="font-family:&#39;宋体&#39;">4.专业事务所/团体组织协会</span><br><span
                                                style="font-family:&#39;宋体&#39;">5.注册资金1000万以上企业</span><br><span
                                                style="font-family:&#39;宋体&#39;">6.4星级及以上酒店部门经理及以上职级管理人员</span><br><span
                                                style="font-family:&#39;宋体&#39;">7.其他企业</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">二、非稳定性职业，包括</span><br><span
                                                style="font-family:&#39;宋体&#39;">1、钟点工、临时工、勤杂工</span><br><span style="font-family:&#39;宋体&#39;">2、一线工人、建筑工人、装修工人</span><br><span
                                                style="font-family:&#39;宋体&#39;">3、电器、机械、家电和汽车维修员</span><br><span
                                                style="font-family:&#39;宋体&#39;">4、保安、保姆、流动商贩、保洁、搬家工、洗车工、水手、快递员、餐馆服务员</span><br><span
                                                style="font-family:&#39;宋体&#39;">5、个体户雇员</span><br><span style="font-family:&#39;宋体&#39;">6、美容、美发、美体行业雇员</span>
                                            </p></td>
                                    </tr>
                                    <tr style="height:97.5pt">
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">三、专业人士</span><br><span
                                                style="font-family:&#39;宋体&#39;">1.全职律师，律师事务所合伙人</span><br><span
                                                style="font-family:&#39;宋体&#39;">2.注册会计师，含CPA/ACCA</span><br><span
                                                style="font-family:&#39;宋体&#39;">3.审计师，国际注册内部审计师</span><br><span
                                                style="font-family:&#39;宋体&#39;">4.专利代理人</span><br><span style="font-family:&#39;宋体&#39;">5.资产评估师</span><br><span
                                                style="font-family:&#39;宋体&#39;">6.税务师</span><br><span
                                                style="font-family:&#39;宋体&#39;">7.医师</span><br><span style="font-family:&#39;宋体&#39;">8.金融分析师、金融风险管理师、理财规划师等</span>
                                            </p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">三、中介机构营销的各类申请</span>
                                            </p></td>
                                    </tr>
                                    <tr style="height:24pt">
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">四、军队武警军官：少尉级以上武职军官、文职九级以上文职军官</span>
                                            </p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">四、申请人工作地和住所所在城市均无光大营业网点</span>
                                            </p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">五、企业主：法人代表、股东、个体工商户负责人</span>
                                            </p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">五、非法职业</span>
                                            </p></td>
                                    </tr>
                                    <tr style="height:13.5pt">
                                        <td style="width:37.88%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10.5pt"><span
                                                style="font-family:&#39;宋体&#39;">　</span></p></td>
                                        <td style="width:52.16%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:middle">
                                            <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">六、军队和武警部队士兵</span>
                                            </p></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">成功推荐定义为</span><span style="font-family:&#39;宋体&#39;">：</span><span
                                    style="font-family:&#39;宋体&#39;">您推荐的</span><span style="font-family:&#39;宋体&#39;">除您本人外的</span><span
                                    style="font-family:&#39;宋体&#39;">客户申请的光大信用为其名下首张光大信用卡，且审批通过后进行开卡激活并完成任意一笔不限金额的消费或取现，以上定义未说明之情况以银行最终解释为准。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您成功推荐客户之后，且您的账户未处于被停用状态，将获得相应的积分。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">一、</span><span style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">注册</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、当</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">注册成功</span><span
                                    style="font-family:&#39;宋体&#39;">账号并且</span><span style="font-family:&#39;宋体&#39;">与某个北京首山</span><span
                                    style="font-family:&#39;宋体&#39;">的合作伙伴</span><span style="font-family:&#39;宋体&#39;">发生行为关联时（</span><span
                                    style="font-family:&#39;宋体&#39;">包括不限于获得优惠券、积分、产生购买行为、享受自由职业者服务等</span><span
                                    style="font-family:&#39;宋体&#39;">），</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">授权该</span><span style="font-family:&#39;宋体&#39;">合作伙伴</span><span
                                    style="font-family:&#39;宋体&#39;">获得</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的手机</span><span style="font-family:&#39;宋体&#39;">账号及由您本人填写的个人信息</span><span
                                    style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、当</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">选择通过第三方授权登录程序进入北京首山，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">需授权北京首山收集</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">昵称及头像等标识化的个人信息，授权完成后，</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">即成为北京首山的</span><span style="font-family:Calibri">“</span><span
                                    style="font-family:&#39;宋体&#39;">用户</span><span style="font-family:Calibri">”</span><span
                                    style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">&nbsp; 当</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">按照注册页面提示填写信息并注册成功后，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">即成为北京首山的</span><span
                                    style="font-family:Calibri">“</span><span style="font-family:&#39;宋体&#39;">用户</span><span
                                    style="font-family:Calibri">”</span><span style="font-family:&#39;宋体&#39;">；北京首山将给予</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">一个用户</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">，密码由</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">自行设置。该用户</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">和密码由</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">自行负责保管。</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; color:#333333; background-color:#ffffff">用户未尽上述义务导致账号</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; background-color:#ffffff">密码遗失</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; color:#333333; background-color:#ffffff">、账号被盗等情形而给自身造成损害的</span><span
                                    style="font-family:Arial; font-size:10pt; color:#333333; background-color:#ffffff">,</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; background-color:#ffffff">由用户</span><span
                                    style="font-family:&#39;宋体&#39;">自己承担</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; background-color:#ffffff">由此</span><span
                                    style="font-family:&#39;宋体&#39;">产生的</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; background-color:#ffffff">直接和间接</span><span
                                    style="font-family:&#39;宋体&#39;">损失</span><span
                                    style="font-family:&#39;宋体&#39;; font-size:10pt; background-color:#ffffff">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应当对</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">在北京首山进行的活动和事件负法律责任。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、本协议服务条款和公告可由北京首山定时更新，并按照法定流程进行公示。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">在使用相关服务时</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">应关注并遵守其所适用的相关条款。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、使用同一身份认证信息、同一手机号或经北京首山排查认定多个北京首山账户的实际控制人为同一人的，均视为同一用户。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">确认，在</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">开始使用本服务前，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">应当具备中华人民共和国法律规定的与</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">行为相适应的民事行为能力。若</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">不具备前述与</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">行为相适应的民事行为能力，则</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">需要在</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">监护人的陪同下完成注册，并且</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">与</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的监护人应依照法律规定承担因此而导致的一切后果。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">二、注册信息和隐私保护</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您账号</span><span style="font-family:&#39;宋体&#39;">的所有权归北京首山，使用权归</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得注册</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">并成为用户。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，由</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">自行承担相应的责任。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应当通过真实身份信息认证注册</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">，且</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">提交的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">名称、头像、 简介等注册信息中不得出现违法和不良信息，经公司审核，如存在上述情况，北京首山将不予注册；同时，在注册后，如发现</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">以虚假信息骗取</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">名称注册，或其</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">头像、简介等注册信息存在违法和不良信息的，北京首山有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您账号</span><span style="font-family:&#39;宋体&#39;">包括</span><span
                                    style="font-family:&#39;宋体&#39;">账</span><span style="font-family:&#39;宋体&#39;">户名称和密码，账户名称作为</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">有效的身份凭证之一。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">可使用手机号和密码登录。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">不应将其</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">、密码转让、出售或出借予他人使用，若</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">授权他人使用</span><span
                                    style="font-family:&#39;宋体&#39;">账</span><span style="font-family:&#39;宋体&#39;">户，应对被授权人在该</span><span
                                    style="font-family:&#39;宋体&#39;">账</span><span style="font-family:&#39;宋体&#39;">户下发生所有行为负全部责任。由于</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">其他账户使用信息，仅当依法律法规、司法裁定或经北京首山同意，并符合北京首山规定的用户</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">转让流程的情况下，方可进行</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">的转让。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、因</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">个人原因导致的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">信息遗失，如需找回</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">信息，请按照</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过安全验证，北京首山有权拒绝提供</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">找回服务；若</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">的唯一凭证不再有效，北京首山有权拒绝支持</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">找回。例如手机号二次出售，北京首山可拒绝支持帮助找回原手机号绑定的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">6</span><span style="font-family:&#39;宋体&#39;">、在需要终止使用</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">服务时，符合以下条件的，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">可以申请注销</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">：</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">1</span><span
                                    style="font-family:&#39;宋体&#39;">）</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">仅能申请注销</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">本人的</span><span style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">，并依照北京首山的流程进行注销；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">2</span><span
                                    style="font-family:&#39;宋体&#39;">）</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">仍应对</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">在注销</span><span style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">前且使用的行为承担相应责任，同时北京首山仍可保存</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">注销前的相关信息；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">3</span><span
                                    style="font-family:&#39;宋体&#39;">）注销成功后，</span><span style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">信息、个人身份信息、交易记录、会员权益等将无法恢复或提供。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、当</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">使用北京首山产品进行支付、登录等操作的时候，服务器会自动接收、验证、并记录一些必要的信息，如手机号码、</span><span
                                    style="font-family:Calibri">IP</span><span style="font-family:&#39;宋体&#39;">地址、服务访问异常信息、以及部分设备信息等，以保障</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">在使用服务时账户登录和支付过程的安全，进而保护</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的上网安全。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">使用北京首山的服务，即表示</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">同意北京首山可以按照《隐私政策》处理</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的个人信息。北京首山可能会与合作伙伴共同向</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">提供</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">所要求的服务或者共同向</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">展示</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">可能感兴趣的内容。在信息为该项产品</span><span style="font-family:Calibri">/</span><span
                                    style="font-family:&#39;宋体&#39;">服务所必须的情况下，</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">同意北京首山可与其分享必要的信息。并且，北京首山会要求其确保数据安全并且禁止用于任何其他用途。除此之外，北京首山不会向任何无关第三方提供或分享信息。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">8</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">知悉并授权，北京首山仅在必</span><span
                                    style="font-family:&#39;宋体&#39;">须</span><span style="font-family:&#39;宋体&#39;">的情况下使用或与关联公司同步</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的信息，以为</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">提供更好的服务。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">9</span><span style="font-family:&#39;宋体&#39;">、为更好地向</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">提供服务，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意北京首山通过短信、</span><span
                                    style="font-family:Calibri">app</span><span style="font-family:&#39;宋体&#39;">通知等形式向</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">发送相关商业性服务信息。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">三、使用规则</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">确认，当</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">注册成功并且与北京首山</span><span
                                    style="font-family:&#39;宋体&#39;">的合作伙伴发生</span><span style="font-family:&#39;宋体&#39;">行为关联时，</span><span
                                    style="font-family:&#39;宋体&#39;">包括不限于获得优惠券、积分、产生购买行为、享受自由职业者服务等</span><span
                                    style="font-family:&#39;宋体&#39;">，</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">授权该</span><span style="font-family:&#39;宋体&#39;">合作伙伴</span><span
                                    style="font-family:&#39;宋体&#39;">获得</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的手机</span><span style="font-family:&#39;宋体&#39;">账号及由您本人填写的个人信息</span><span
                                    style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">在使用北京首山的服务时，必须遵守中华人民共和国相关法律法规的规定，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为</span><span
                                    style="font-family:Calibri">:</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">1</span><span
                                    style="font-family:&#39;宋体&#39;">）</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">设置的会员名或提供的信息不得含有下列内容之一的信息：</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">① </span><span
                                    style="font-family:&#39;宋体&#39;">反对宪法所确定的基本原则的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">② </span><span
                                    style="font-family:&#39;宋体&#39;">危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">③ </span><span
                                    style="font-family:&#39;宋体&#39;">损害国家荣誉和利益的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">④ </span><span
                                    style="font-family:&#39;宋体&#39;">煽动民族仇恨、民族歧视、破坏民族团结的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">⑤ </span><span
                                    style="font-family:&#39;宋体&#39;">破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">⑥ </span><span
                                    style="font-family:&#39;宋体&#39;">散布谣言，扰乱社会秩序，破坏社会稳定的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">⑦ </span><span
                                    style="font-family:&#39;宋体&#39;">散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">⑧ </span><span
                                    style="font-family:&#39;宋体&#39;">侮辱或者诽谤他人，侵害他人合法权利的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">⑨ </span><span
                                    style="font-family:&#39;宋体&#39;">含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">⑩ </span><span
                                    style="font-family:&#39;宋体&#39;">含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">2</span><span
                                    style="font-family:&#39;宋体&#39;">）不得为任何非法目的而使用北京首山服务系统；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">3</span><span
                                    style="font-family:&#39;宋体&#39;">）不利用北京首山的服务从事以下活动：</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">① </span><span
                                    style="font-family:&#39;宋体&#39;">未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">② </span><span
                                    style="font-family:&#39;宋体&#39;">未经允许，对计算机信息网络功能进行删除、修改或者增加的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">③ </span><span
                                    style="font-family:&#39;宋体&#39;">未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">　　</span><span style="font-family:Calibri">④ </span><span
                                    style="font-family:&#39;宋体&#39;">故意制作、传播计算机病毒等破坏性程序的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-indent:21pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                                    <span style="font-family:Calibri">⑤ </span><span style="font-family:&#39;宋体&#39;">其他危害计算机信息网络安全的行为。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-indent:21pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                                    <span style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意赔偿北京首山与合作公司、关联公司，并使之免受损害。对此，北京首山有权视</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的行为性质，采取包括但不限于删除</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">发布信息内容、暂停使用许可、终止服务、限制使用、回收</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">、追究法律责任等措施。对恶意注册</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">或利用</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，北京首山有权回收其</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span
                                    style="font-family:&#39;宋体&#39;">。同时，北京首山会视司法部门的要求，协助调查。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">需要对自己在服务使用过程中的行为负法律责任，即使</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">已经终止使用该服务。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在北京首山首先承担了因</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的行为导致的行政处罚或侵权损害赔偿责任后，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应给予北京首山等额的赔偿。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、北京首山有权根据认定程序及标准判断</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">账户的使用可能危及</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的账户安全及</span><span
                                    style="font-family:Calibri">/</span><span
                                    style="font-family:&#39;宋体&#39;">或北京首山平台信息安全的，北京首山可拒绝提供相应服务或终止本协议。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">四、服务内容</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">在交易过程中与其他用户发生争议的，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">或其他用户中任何一方可以自行协商解决，也可以通过北京首山介入处理，若</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">觉得以上的方式均无法达到</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">要的结果，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">可以寻求司法机关的协助。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">选择北京首山介入的方式处理相关问题，那么</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">需要遵守和执行北京首山的处理结果。若</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">对北京首山的处理结果不满意，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">可以寻求其他途径解决纠纷例如起诉、仲裁等方式，但在此之前</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">需先履行该结果。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、北京首山目前为</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">提供免费提供服务，但北京首山保留因业务调整或者其他法律法规要求向</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">收取费用的权利。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span
                                    style="font-family:&#39;宋体&#39;">、北京首山网络服务的具体内容由北京首山根据实际情况提供。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、鉴于网络服务的特殊性，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意北京首山有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。北京首山不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">6</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">需遵守北京首山</span><span
                                    style="font-family:&#39;宋体&#39;">信用卡及其他产品市场活动、任务的</span><span style="font-family:&#39;宋体&#39;">相关规则。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">首刷礼活动，全称为信用卡首刷完成礼品奖励活动。您参与北京首山的首刷礼活动，按照本用户协议条款《五、首刷礼活动规则》提供服务，最终解释权归北京首山所有；您使用北京首山的服务，即表示您同意北京首山按照本用户协议条款《五、首刷礼活动规则》提供服务。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">8</span><span style="font-family:&#39;宋体&#39;">、积分服务，您参与北京首山的市场活动、任务获得，积分专属北京首山，按照本用户协议条款《六、积分规则》提供服务，最终解释权归北京首山所有；您使用北京首山的服务，即表示您同意北京首山按照本用户协议条款《六、积分规则》提供服务。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">9</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">积分提现服务，您</span><span
                                    style="font-family:&#39;宋体&#39;">使用北京首山的服务，即表示</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">同意北京首山</span><span style="font-family:&#39;宋体&#39;">的合作伙伴</span><span
                                    style="font-family:&#39;宋体&#39;">可以按照</span><span style="font-family:&#39;宋体&#39;">《自由职业者服务协议</span><span
                                    style="font-family:&#39;宋体&#39;">》处理</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的</span><span style="font-family:&#39;宋体&#39;">提现要求</span><span
                                    style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">10</span><span style="font-family:&#39;宋体&#39;">、收货后结算服务，即第三方代收代付功能：在资金支付完成前（故该服务无法提供给即时到账、储值卡充值等未经北京首山支付代管资金的业务），由交易相对方提供不可撤销地授权北京首山支付，由北京首山支付（或北京首山支付的协议银行）进行资金代为收取、支付、存放、划转。在使用该功能时</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">买家按照流程点击确认收货或根据买卖双方的约定视为确认收货后，北京首山支付将代为收取的买家支付的款项代为支付给卖家。若买卖双方与相关交易平台就全部或部分交易款项的支付时间存在其他特殊约定的，则按照特殊约定执行，如：预付卡支付方式（储值卡等），以及其他排除北京首山支付的情形，如：虚拟类商品交易（话费充值、培训课程等）。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">11</span><span style="font-family:&#39;宋体&#39;">、终止服务</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意北京首山得基于其自行之考虑，因任何理由，或北京首山认为</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">已经违反本服务协议的文字及精神，终止</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的密码、</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">或本服务之使用（或服务之任何部分），并将</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">在本服务内任何内容加以移除并删除。</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">承认并同意，北京首山可立即关闭或删除</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">及</span><span
                                    style="font-family:&#39;宋体&#39;">您账号</span><span style="font-family:&#39;宋体&#39;">中所有相关信息及文件，及</span><span
                                    style="font-family:Calibri">/</span><span style="font-family:&#39;宋体&#39;">或禁止继续使用前述文件或本服务。此外，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意若本服务之使用被中断或终止或</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的</span><span
                                    style="font-family:&#39;宋体&#39;">账号</span><span style="font-family:&#39;宋体&#39;">及相关信息和文件被关闭或删除，北京首山对</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">或任何第三人均不承担任何责任。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">五、首刷礼活动规则</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、总体规则</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①首刷礼活动，全称为信用卡首刷完成礼品奖励活动。首刷完成是指您申请指某银行信用卡为您名下首张该银行发行的信用卡，且审批通过后进行开卡激活并在激活后的</span><span
                                    style="font-family:Calibri">30</span><span style="font-family:&#39;宋体&#39;">个自然日内完成特定金额的消费或取现。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②每个首刷礼活动参与、礼品兑换、礼品使用均有不同有效时间。您需要在有效时间内参与、兑换和使用首刷礼品。如果超过有效期，则视为您主动放弃，不再补发，北京首山为此不承担任何责任。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③首刷礼兑换权利不可转让、不可买卖、不可提现。礼品不可折现。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、活动参与</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">有效参与首刷礼活动，是指您通过指定办卡链接填写表单，申请特定卡片，在指定活动时间内完成特定金额的消费或取现，且在指定参与人数内参与活动。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、礼品兑换</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①您有效参与了首刷礼活动，在首刷完成后，需在指定时间内在指定平台申请兑换首刷礼。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②首刷活动礼品由北京首山发放的，您需要在首刷完成后的</span><span style="font-family:Calibri">3</span><span
                                    style="font-family:Calibri">0</span><span style="font-family:&#39;宋体&#39;">个自然日内登录北京首山首卡小程序进行兑换。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、首刷礼品</span></p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">①北京首山将通过北京首山光有你小程序发布并更新首刷礼品目录。小程序或宣传品中的首刷礼品图片仅供参考，以实物为准，部分礼品颜色、花色随机发送。</span>
                                </p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">②首刷礼品兑换属回馈活动，恕不提供发票。若非质量问题，所有首刷礼品均不可退货、退换、退款、退积分或兑换现金。如所兑换的首刷礼品在运送过程中毁损或首刷礼品本身有质量问题时，您须在签收日起的</span><span
                                    style="font-family:&#39;宋体&#39;"> </span><span style="font-family:Calibri">7</span><span
                                    style="font-family:&#39;宋体&#39;">天内致电首刷礼品包装上注明的首刷礼品供应商销售热线提出换货申请，超过</span><span style="font-family:Calibri">7</span><span
                                    style="font-family:&#39;宋体&#39;">天将不予受理。退回时，请务必保留原包装、内附说明书、附件及相关凭证。如您在使用过程中发现首刷礼品质量问题，可致首刷礼品供应商申请维修。</span>
                                </p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">③本首刷礼活动所提供的首刷礼品商品，由各首刷礼品供应商提供，其质量、数量、款式、颜色、售后服务等问题均由供应商负责，北京首山不提供任何形式的担保和承诺。</span>
                                </p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">④因您所兑换的首刷礼品而发生的相应所得税均由您自行承担和申报。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、礼品配送</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①成功兑换的首刷礼品均提供配送到户服务，通常在您提出兑换申请后的</span><span
                                    style="font-family:Calibri">20</span><span style="font-family:&#39;宋体&#39;">天内（元旦、春节、五一、十一等国定假日除外）进行订单处理和配送服务。实物实物首刷礼品由各实物首刷礼品供应商按您该信用卡银行预留地址（仅限中国大陆地区，不含港、澳、台）寄达；非实物首刷礼品如话费、电子优惠券、电子会员券等仅发送至您该信用卡银行预留手机。微信立减金发送至使用该信用卡银行预留手机号注册或绑定的微信。请在兑换申请时务必核实地址、邮编、手机号码，确保信息的正确及完整。如因地址不详、错误或变更未及时通知该信用卡发卡行或无人收货、手机号码未预留或错误、关机、停机等原因造成的延迟送达、未能送达等均由您自行负责。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②您签收实物礼品时，须出示本人身份证件（代签的除出示您本人身份证件以外还需出示代签人的身份证件）以供查验，并按要求在签收单上签字。您或代签收人在签收首刷礼品时应当场检查首刷礼品的完好性，若存在质量问题或缺损可拒绝签收；您或代签收人一旦签收即代表首刷礼品收妥。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③非实物首刷礼品，短信发送即视为收妥。微信立减金，微信发送即视为收妥，且微信立减金一旦发放，</span><span
                                    style="font-family:Calibri">3</span><span style="font-family:Calibri">0</span><span
                                    style="font-family:&#39;宋体&#39;">个自然日内有效，过期作废，不做补发。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:Calibri">&nbsp;</span><span style="font-family:&#39;宋体&#39;">免责条款</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                                    <span style="font-family:Calibri">①</span><span
                                    style="font:7pt &#39;Times New Roman&#39;">&nbsp;&nbsp; </span><span style="font-family:&#39;宋体&#39;">首刷礼不可转让，仅可在北京首山光有你小程序使用</span><span
                                    style="font-family:Calibri">;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③ 北京首山有权在未向您通知的情况下自行变更、终止全部或部分首刷礼规则</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:Calibri"> </span><span
                                    style="font-family:&#39;宋体&#39;">北京首山无须为首刷礼规则的变更或终止承担任何责任。您对北京首山光有你小程序的继续使用</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">视为您对首刷礼规则变更和终止的接受</span><span
                                    style="font-family:Calibri">;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">⑦ 首刷礼规则以北京首山光有你小程序公布的最新版本为准，最终解释权由北京首山所有。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">六、积分规则</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、总体规则</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①</span><span style="font-family:&#39;宋体&#39;">积分专属</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山，</span><span style="font-family:&#39;宋体&#39;">仅限</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山光有你小程序</span><span style="font-family:&#39;宋体&#39;">内使用</span><span
                                    style="font-family:&#39;宋体&#39;">；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②</span><span style="font-family:&#39;宋体&#39;">积分可以累积</span><span
                                    style="font-family:&#39;宋体&#39;">，</span><span style="font-family:&#39;宋体&#39;">自发放日起</span><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">年内有效</span><span
                                    style="font-family:&#39;宋体&#39;">，</span><span style="font-family:&#39;宋体&#39;">逾期自动失效。</span><span
                                    style="font-family:&#39;宋体&#39;">如若交易在使用的积分有效期之外发生退款，该部分积分不予退还；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③</span><span style="font-family:&#39;宋体&#39;">积分</span><span
                                    style="font-family:&#39;宋体&#39;">兑换</span><span style="font-family:&#39;宋体&#39;">比例为</span><span
                                    style="font-family:&#39;宋体&#39;">：</span><span style="font-family:Calibri">1</span><span
                                    style="font-family:&#39;宋体&#39;">积分</span><span style="font-family:Calibri">=</span><span
                                    style="font-family:&#39;宋体&#39;">人民币</span><span style="font-family:Calibri">1</span><span
                                    style="font-family:&#39;宋体&#39;">元。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">④买家在使用积分时，优先消耗旧积分（如买家积分由去年</span><span style="font-family:Calibri">3</span><span
                                    style="font-family:&#39;宋体&#39;">月份和今年</span><span style="font-family:Calibri">5</span><span
                                    style="font-family:&#39;宋体&#39;">月份共同累积，则优先消耗去年</span><span style="font-family:Calibri">3</span><span
                                    style="font-family:&#39;宋体&#39;">月份的积分）；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">⑤积分可按本用户协议条款《自由职业者服务协议</span><span
                                    style="font-family:&#39;宋体&#39;">》</span><span style="font-family:&#39;宋体&#39;">进行提现，不可转让。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、积分获取</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①您</span><span style="font-family:&#39;宋体&#39;">在</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山光有你小程序参加信用卡推广活动，</span><span
                                    style="font-family:&#39;宋体&#39;">可以获得积分</span><span style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②</span><span style="font-family:&#39;宋体&#39;">积分的数值精确到个位</span><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:&#39;宋体&#39;">小数点后全部舍弃</span><span
                                    style="font-family:&#39;宋体&#39;">，</span><span style="font-family:&#39;宋体&#39;">不进行四舍五入</span><span
                                    style="font-family:&#39;宋体&#39;">）；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③</span><span style="font-family:&#39;宋体&#39;">您成功推荐</span><span
                                    style="font-family:&#39;宋体&#39;">除您本人外的</span><span style="font-family:&#39;宋体&#39;">客户之后，且您的账户未处于被停用状态，将获得相应的积分</span><span
                                    style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">④积分奖励以北京首山光有你小程序公告为准。您</span><span style="font-family:&#39;宋体&#39;">使用北京首山的服务，即表示</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意北京首山</span><span
                                    style="font-family:&#39;宋体&#39;">按照北京首山光有你小程序的公告进行积分奖励</span><span style="font-family:&#39;宋体&#39;">。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、积分查询</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您可在北京首山光有你小程序</span><span style="font-family:Calibri">-</span><span
                                    style="font-family:&#39;宋体&#39;">个人中心板块查询积分获取及使用明细。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、积分使用</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">您可以在北京首山光有你小程序</span><span style="font-family:Calibri">-</span><span
                                    style="font-family:&#39;宋体&#39;">福利商城</span><span style="font-family:&#39;宋体&#39;">板块</span><span
                                    style="font-family:&#39;宋体&#39;">使用积分。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、积分福利商品</span></p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">①北京首山将通过北京首山光有你小程序发布并更新积分福利商品目录。小程序或宣传品中的积分福利商品图片仅供参考，以实物为准，部分礼品颜色、花色随机发送。</span>
                                </p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">②积分商品兑换属回馈活动，恕不提供发票。若非质量问题，所有积分福利商品均不可退货、退换、退款、退积分或兑换现金。如所兑换的积分福利商品在运送过程中毁损或积分福利商品本身有质量问题时，您须在签收日起的</span><span
                                    style="font-family:&#39;宋体&#39;"> </span><span style="font-family:Calibri">7</span><span
                                    style="font-family:&#39;宋体&#39;">天内致电福利商品包装上注明的福利商品供应商销售热线提出换货申请，超过</span><span style="font-family:Calibri">7</span><span
                                    style="font-family:&#39;宋体&#39;">天将不予受理。退回时，请务必保留原包装、内附说明书、附件及相关凭证。如您在使用过程中发现福利商品质量问题，可致电福利商品供应商申请维修。</span>
                                </p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">③本积分活动所提供的积分福利商品，由各积分福利商品供应商提供，其质量、数量、款式、颜色、售后服务等问题均由供应商负责，北京首山不提供任何形式的担保和承诺。</span>
                                </p>
                                <p style="margin-top:3.75pt; margin-bottom:3.75pt; font-size:10.5pt"><span style="font-family:&#39;宋体&#39;">④因您所兑换的积分福利商品而发生的相应所得税均由您自行承担和申报。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">6</span><span style="font-family:&#39;宋体&#39;">、积分礼品的配送</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①成功兑换的积分福利商品均提供配送到户服务，通常在您提出兑换申请后的</span><span style="font-family:Calibri">20</span><span
                                    style="font-family:&#39;宋体&#39;">天内（元旦、春节、五一、十一等国定假日除外）进行订单处理和配送服务。实物福利商品由各积分福利商品供应商按您订单预留地址（仅限中国大陆地区，不含港、澳、台）寄达；非实物福利商品如话费、电子优惠券、电子会员券等仅发送至您订单预留手机。请在兑换申请时务必核实地址、邮编、手机号码，确保信息的正确及完整。如因地址不详、错误或变更未及时通知北京首山或无人收货、手机号码未预留或错误等原因造成的延迟送达、未能送达等均由您自行负责。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②您签收实物礼品时，须出示本人身份证件（代签的除出示您本人身份证件以外还需出示代签人的身份证件）以供查验，并按要求在签收单上签字。您或代签收人在签收福利商品时应当场检查福利商品的完好性，若存在质量问题或缺损可拒绝签收；您或代签收人一旦签收即代表福利商品收妥。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、积分提现</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①积分提现仅为与</span><span style="font-family:&#39;宋体&#39;">北京首山</span><span
                                    style="font-family:&#39;宋体&#39;">的合作伙伴签订《自由职业者服务协议</span><span
                                    style="font-family:&#39;宋体&#39;">》</span><span style="font-family:&#39;宋体&#39;">的用户提供。您</span><span
                                    style="font-family:&#39;宋体&#39;">使用北京首山的服务</span><span style="font-family:&#39;宋体&#39;">前需与</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山</span><span
                                    style="font-family:&#39;宋体&#39;">的合作伙伴签订《自由职业者服务协议</span><span
                                    style="font-family:&#39;宋体&#39;">》</span><span style="font-family:&#39;宋体&#39;">。您</span><span
                                    style="font-family:&#39;宋体&#39;">使用北京首山的服务即表示</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">同意北京首山</span><span style="font-family:&#39;宋体&#39;">的合作伙伴</span><span
                                    style="font-family:&#39;宋体&#39;">可以按照</span><span style="font-family:&#39;宋体&#39;">《自由职业者服务协议</span><span
                                    style="font-family:&#39;宋体&#39;">》处理</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的</span><span style="font-family:&#39;宋体&#39;">提现要求</span><span
                                    style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②</span><span style="font-family:&#39;宋体&#39;">提现需要缴纳</span><span
                                    style="font-family:Calibri">20%</span><span style="font-family:&#39;宋体&#39;">的个人所得税</span><span
                                    style="font-family:&#39;宋体&#39;">，</span><span style="font-family:&#39;宋体&#39;">该税款由</span><span
                                    style="font-family:&#39;宋体&#39;">河南芸柏科技有限公司代缴</span><span style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">6</span><span style="font-family:&#39;宋体&#39;">、积分退还</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①商品发生退货时</span><span style="font-family:Calibri">,</span><span
                                    style="font-family:&#39;宋体&#39;">商品抵扣积分根据商品数量分摊后退回用户帐户</span><span style="font-family:Calibri">,</span><span
                                    style="font-family:&#39;宋体&#39;">购物积分根据订单中的商品金额均摊后从用户帐户中扣除</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">如账户积分不足则扣为负值。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②如商品退货时一并退回的商品抵扣积分已超过该积分的有效期</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">则该部分积分自动失效。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③订单取消的积分退还参照商品退货的积分退还标准执行。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">④已提现的积分不予退还。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:Calibri">&nbsp;</span><span style="font-family:&#39;宋体&#39;">免责条款</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">①积分不可转让，仅可在北京首山光有你小程序使用</span><span style="font-family:Calibri">;</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">②您在北京首山光有你小程序参加特定活动也可使用积分</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">详细使用规则以具体活动时的规则为准</span><span
                                    style="font-family:Calibri">;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">③北京首山有权在未向您通知的情况下自行变更、终止全部或部分积分规则</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:Calibri"> </span><span
                                    style="font-family:&#39;宋体&#39;">北京首山无须为积分规则的变更或终止承担任何责任。您对北京首山光有你小程序的继续使用</span><span
                                    style="font-family:Calibri">,</span><span style="font-family:&#39;宋体&#39;">视为您对积分规则变更和终止的接受</span><span
                                    style="font-family:Calibri">;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">⑥无论您的北京首山光有你小程序账户因何种原因被关闭，您的积分将自动丧失，北京首山光有你小程序无须为您的积分损失承担任何责任。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">⑦积分规则以北京首山光有你小程序公布的最新版本为准，最终解释权由北京首山所有。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">七、</span><span style="font-family:&#39;宋体&#39;">个人数据保护与授权</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、为了保护</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的财产及信息安全，预防诈骗或者其他网络犯罪行为，北京首山有权收集</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的个人信息，包括但不限于个人会员信息、交易信息来判断</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">个人的交易风险，对</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">身份进行验证等。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、根据相关法律法规规定，以下情形中收集</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的个人信息无需征得</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的授权同意：</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">①</span><span style="font-family:&#39;宋体&#39;">、涉及公共安全，与重大利益相关的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">②</span><span style="font-family:&#39;宋体&#39;">、与政府部门包括司法机关等相关的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">③</span><span style="font-family:&#39;宋体&#39;">、出于保护</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的个人财产或者利益的目的，但无法事先征求</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">同意的行为；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">④</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">以自行对外公开的，包括但不限于通过报纸、新闻等方式公开；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">⑤</span><span style="font-family:&#39;宋体&#39;">、根据</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">的要求签订合同所必需的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">⑥</span><span style="font-family:&#39;宋体&#39;">、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">⑦</span><span style="font-family:&#39;宋体&#39;">、国家法律法规政策认可或支持的相关行为；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、我们已采取符合业界标准、合理可行的安全防护措施保护</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">八</span><span style="font-family:&#39;宋体&#39;">、免责声明</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">因以下情况造成网络服务在合理时间内的中断，北京首山无需为此承担任何责任；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">1</span><span
                                    style="font-family:&#39;宋体&#39;">）北京首山需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，北京首山保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">2</span><span
                                    style="font-family:&#39;宋体&#39;">）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">3</span><span
                                    style="font-family:&#39;宋体&#39;">）</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">的电脑软硬件和通信线路、供电线路出现故障的；</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">4</span><span
                                    style="font-family:&#39;宋体&#39;">）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">尽管有前款约定，北京首山将采取合理行动积极促使服务恢复正常。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">九</span><span style="font-family:&#39;宋体&#39;">、知识产权和其他合法权益</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、北京首山产品及相关网站上由北京首山上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由北京首山或其关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。但如因</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">上传的由其自行制作、拥有的内容涉及侵犯北京首山或其他任何第三方的合法权益的，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给北京首山造成的损失（包括但不限于北京首山声誉的影响、北京首山由此承担的连带责任（如有）等）进行赔偿。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、非经北京首山或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在本网站上程序或内容（仅限于由北京首山上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计等）；如</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">需使用著作权非北京首山所有的内容的，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">应获得具体内容的著作权所有者的合法授权才能使用，如因</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">私自使用非自己所有的、且未经他人合法授权的著作、图片、档案、资讯、资料等内容的，由</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">自行承担责任，包括但不限于</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给乙方造成的损失（包括但不限于乙方声誉的影响、乙方由此承担的连带责任（如有）等）进行赔偿。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、尊重知识产权是</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应尽的义务，如有违反，</span><span
                                    style="font-family:&#39;宋体&#39;">您</span><span style="font-family:&#39;宋体&#39;">应承担损害赔偿责任。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">十</span><span style="font-family:&#39;宋体&#39;">、其他</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约</span><span
                                    style="font-family:&#39;宋体&#39;">行为弃权。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、北京首山未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</span>
                                </p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">5</span><span
                                    style="font-family:&#39;宋体&#39;">、本协议最终解释权及修订权在法律范围内归北京首山所有。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">6</span><span
                                    style="font-family:&#39;宋体&#39;">、该小程序涉及的所有业务规则及功能定义最终解释权归</span><span
                                    style="font-family:&#39;宋体&#39;">北京首山</span><span style="font-family:&#39;宋体&#39;">所有。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、您</span><span
                                    style="font-family:&#39;宋体&#39;">因使用北京首山服务产生的争议，由北京首山与</span><span style="font-family:&#39;宋体&#39;">您</span><span
                                    style="font-family:&#39;宋体&#39;">协商解决。协商不成时，任何一方均可向</span><a name="_cmntref1"><span
                                    style="font-family:&#39;宋体&#39;">北京市朝阳区</span><span style="font-family:&#39;宋体&#39;">人民法院</span></a><span
                                    style="font-family:&#39;宋体&#39;">提起诉讼</span><span style="font-family:&#39;宋体&#39;">。</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">&nbsp;</span></p>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:Calibri">8</span><span style="font-family:&#39;宋体&#39;">、协议签署地：北京市朝阳区</span></p></div>
                        </van-tab>
                        <van-tab title="隐私政策" name="privacyPolicy">
                            <div class="rich-text modal-rich-txt">
                                <div><p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                    style="font-family:&#39;宋体&#39;">北京首山</span><span style="font-family:&#39;宋体&#39;">管理</span><span
                                    style="font-family:&#39;宋体&#39;">咨询有限公司</span><span style="font-family:&#39;宋体&#39;">（以</span><span
                                    style="font-family:&#39;宋体&#39;">下称“北京首山”或“我们”</span><span style="font-family:&#39;宋体&#39;">）将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，我们制定本《隐私政策》（下称</span><span
                                    style="font-family:Calibri">“</span><span style="font-family:&#39;宋体&#39;">本政策</span><span
                                    style="font-family:Calibri">”</span><span style="font-family:&#39;宋体&#39;">）并提醒您：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">本政策适用于北京首山产品及服务，包括北京首山网（域名为</span><span
                                        style="font-family:Calibri">qulicai</span><span style="font-family:Calibri">.com</span><span
                                        style="font-family:&#39;宋体&#39;">）及北京首山云、北京首山公众号、北京首山小程序、北京首山</span><span
                                        style="font-family:Calibri">H5</span><span
                                        style="font-family:&#39;宋体&#39;">页面及客户端、北京首山智能硬件以及将北京首山部分功能</span><span style="font-family:Calibri">/</span><span
                                        style="font-family:&#39;宋体&#39;">服务作为第三方服务集成在北京首山关联公司（见定义条款）和</span><span
                                        style="font-family:Calibri">/</span><span style="font-family:&#39;宋体&#39;">或其他第三方智能硬件、软件或服务中。如我们关联公司（范围详见定义部分）的产品或服务中使用了北京首山提供的产品或服务但未设独立的隐私政策的，则本政策同样适用于该部分产品或服务。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">需要特别说明的是，本隐私政策不适用于其他第三方通过前述网页或客户端或者小程序或公众号等向您提供的服务。例如您通过北京首山使用第三方应用（如北京首山插件市场）或者服务，或第三方服务和产品依托北京首山产品向您提供服务时，您向第三方提供的信息不适用本政策。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">在使用北京首山产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务，如您对本隐私政策有任何疑问，您可以通过我们公布的联系方式与我们联系。一旦您开始使用北京首山产品或服务，即表示您已充分理解并同意本政策，即表示您已同意我们按照本隐私政策来收集、处理、存储、使用、共享和保护您的个人信息。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">第一部分 定义</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">北京首山：是指研发并提供北京首山产品和服务的北京首山</span><span style="font-family:&#39;宋体&#39;">管理咨询</span><span
                                        style="font-family:&#39;宋体&#39;">有限公司及现在或未来设立的相关关联公司的单称或合称。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">北京首山服务：是指由北京首山开发和服务的</span><span
                                        style="font-family:&#39;宋体&#39;">小程序</span><a name="_cmntref1"><span
                                        style="font-family:&#39;宋体&#39;">首卡</span></a><span
                                        style="font-family:&#39;宋体&#39;">。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">关联公司：指中国北京首山</span><span style="font-family:&#39;宋体&#39;">管理咨询</span><span
                                        style="font-family:&#39;宋体&#39;">有限公司披露的北京首山服务提供者的关联公司</span><span style="font-family:&#39;宋体&#39;">。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">个人敏感信息：包括身份证件号码、手机号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、</span><span
                                        style="font-family:Calibri">14</span><span style="font-family:&#39;宋体&#39;">岁以下（含）儿童的个人信息等。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">第二部分 隐私政策</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、我们如何收集和使用您的个人信息；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、我们如何使用 </span><span
                                        style="font-family:Calibri">Cookie </span><span style="font-family:&#39;宋体&#39;">和同类技术；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、我们如何共享、转让、公开披露您的个人信息；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、我们如何保护您的个人信息；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、您如何行使您的个人信息权利；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">6</span><span style="font-family:&#39;宋体&#39;">、我们如何处理儿童的个人信息；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、您的个人信息如何在全球范围转移；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">8</span><span style="font-family:&#39;宋体&#39;">、本政策如何更新；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">9</span><span style="font-family:&#39;宋体&#39;">、如何联系我们。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">一、我们如何收集和使用您的个人信息</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们会出于本政策所述的以下目的，收集和使用您的个人信息：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（一）提供账号服务</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">为了遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，我们需要识别您的身份，当您注册我们的账户及您在使用我们的相关服务时，需要您授权您的微信或微博等第三方账号的</span><span
                                        style="font-family:Calibri">ID</span><span style="font-family:&#39;宋体&#39;">及头像和昵称，您需要填写、提交及</span><span
                                        style="font-family:Calibri">/</span><span style="font-family:&#39;宋体&#39;">或以其他任何方式提供您的个人信息，包括但不限于您的姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱、收货地址及相关附加信息（如您地址中的所在省份和城市、邮政编码等）、银行账户或其他支付工具的账户信息等。如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的账号及提供上述信息。如您不提供真实姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱也不影响您使用北京首山的服务进行基本浏览、搜索、购买功能，但无法使用法律必须需要提供个人信息的高面额的储值卡、预付款、跨境电商等服务。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（二）为您展示或推送商品或服务</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">为给您提供更好的交易服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息、及可能的个人身份信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签进行间接人群画像并展示、推送信息。如您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（三）向您提供商品或服务</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、您向我们提供的信息</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">为便于向您提供我们的产品或服务，我们会记录您提供的收货人姓名、收货地址、邮政编码、收货人联系电话。为完成交易，我们将搜集您的支付信息，以便我们了解您的支付状态。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">&nbsp;&nbsp; 您可以通过北京首山为其他人订购商品或服务或要求其他人帮您付款，您需要提供该实际订购人的前述个人信息或提供代付人的支付信息或手机号码。向北京首山提供这些个人信息之前，您需确保您已经取得其授权同意。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、我们在您使用服务过程中收集的信息</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账户异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">&nbsp;&nbsp;&nbsp;&nbsp; 设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本等软硬件特征和设备环境信息）、设备所在位置相关信息（例如</span><span
                                        style="font-family:Calibri">IP </span><span style="font-family:&#39;宋体&#39;">地址、</span><span
                                        style="font-family:Calibri">GPS</span><span style="font-family:&#39;宋体&#39;">位置以及能够提供相关信息的</span><span
                                        style="font-family:Calibri">Wi-Fi </span><span style="font-family:&#39;宋体&#39;">接入点、蓝牙和基站等传感器信息）。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的</span><span
                                        style="font-family:Calibri">IP</span><span style="font-family:&#39;宋体&#39;">地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">为展示您账户的订单信息，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">当您与我们联系时，我们可能会保存您的通信</span><span style="font-family:Calibri">/</span><span
                                        style="font-family:&#39;宋体&#39;">通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、我们通过间接获得方式收集到的您的个人信息</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、交易方式、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">您可通过我们提供的链接入口使用北京首山账户登录我们关联公司或者合作公司提供的产品或服务，包括来自微信、微博、快手、小程序等的信息。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">您在使用第三方服务时，可以直接使用第三方服务时直接跳转到我们提供服务的页面，如您进行了除浏览以外的行为，我们将可能收集到您的个人信息（包括您在第三账号的</span><span
                                        style="font-family:Calibri">ID</span><span style="font-family:&#39;宋体&#39;">、头像、昵称等）。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（四）为您提供安全保障</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">&nbsp;&nbsp;&nbsp; 为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，我们可能使用或整合您的个人信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（五）其他用途</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（六）征得授权同意的例外</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span
                                        style="font-family:&#39;宋体&#39;">、根据法律法规的规定或行政、司法机构等有权机关的要求或者授权；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、您出现违反适用法律、法规规定或者违反您与我们签署的相关协议（包括在线签署的电子协议）或相关规则时需要向第三方披露的情形。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、我们与第三方进行联合营销活动，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息，如参加活动的用户数、中奖名单、中奖人联系方式等，以便第三方能及时向您发放奖品。如与授权合作伙伴共享，仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您使用我们配送服务时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、为维护我们及关联方或其他我们用户的合法权益；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">6</span><span style="font-family:&#39;宋体&#39;">、出于实现本政策公示的我们如何使用您的个人信息的目的，或为履行我们和您签订的协议中或本隐私政策中的义务和行使我们的权利，向我们的关联方、合作伙伴或代表我们履行某项职能的第三方（例如处理银行卡的支付机构等）分享您的个人信息。；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">7</span><span style="font-family:&#39;宋体&#39;">、法律法规规定的其他情形。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">如我们停止运营北京首山产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">二、我们如何使用 </span><span style="font-family:Calibri">Cookie </span><span
                                        style="font-family:&#39;宋体&#39;">和同类技术</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（一）</span><span style="font-family:Calibri">Cookie</span><span
                                        style="font-family:&#39;宋体&#39;">的使用</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名</span><span
                                        style="font-family:Calibri">Cookie</span><span style="font-family:&#39;宋体&#39;">、</span><span
                                        style="font-family:Calibri">Flash Cookie</span><span style="font-family:&#39;宋体&#39;">或浏览器或关联应用程序提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称</span><span
                                        style="font-family:Calibri">“Cookie”</span><span style="font-family:&#39;宋体&#39;">）。借助于 </span><span
                                        style="font-family:Calibri">Cookie</span><span
                                        style="font-family:&#39;宋体&#39;">，网站能够存储您的偏好或购物车内的商品等数据。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">如果您的浏览器或浏览器附加服务允许，您可修改对</span><span
                                        style="font-family:Calibri">Cookie</span><span style="font-family:&#39;宋体&#39;">的接受程度或拒绝我们的</span><span
                                        style="font-family:Calibri">Cookie</span><span style="font-family:&#39;宋体&#39;">。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，而无法使用部分我们提供的服务。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（二）网站信标和像素标签</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">除</span><span style="font-family:Calibri">Cookie </span><span
                                        style="font-family:&#39;宋体&#39;">外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">三、我们如何共享、转让、公开披露您的个人信息</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（一）共享</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们不会与北京首山服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、在您主动选择情况下共享：若您主动通过北京首山购买商品和服务，我们会根据您的选择，将您的订单信息与交易有关的必要信息与相关商品和服务的提供者共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。您可以在相关商品和服务的提供者从事经营活动的主页面查询其营业执照登载的信息，以辨别您的交易对象身份。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、在获取明确同意情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。例如，如果我们委托第三方向您提供产品或服务时，我们会在征得您同意后将上述信息共享给第三方，如果您拒绝提供此类信息，我们将无法完成相关交付服务。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、在法定情形下共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、与关联公司共享：为便于我们基于北京首山账户向您提供服务，推荐您可能感兴趣的信息或保护北京首山关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">5</span><span style="font-family:&#39;宋体&#39;">、与授权合作伙伴共享：我们会委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到为其履行职责所需信息，且不能将此信息用于任何其他目的。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">目前，我们的授权合作伙伴包括以下类型：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">1</span><span
                                        style="font-family:&#39;宋体&#39;">）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（</span><span style="font-family:Calibri">2</span><span
                                        style="font-family:&#39;宋体&#39;">）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">&nbsp;&nbsp;&nbsp; 对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（二）转让</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、在北京首山服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（三）公开披露</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们仅会在以下情况下，公开披露您的个人信息：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、如果我们确定您出现违反法律法规或严重违反北京首山相关协议规则的情况，或为保护北京首山及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或北京首山相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及北京首山已对您采取的措施。例如，若您销售假货而严重违反北京首山规则，我们可能会公开披露您的店铺主体信息与处罚情况。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、根据法律法规的规定或行政、司法机构、国家部门等有权机关的要求依法提供的；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、您自行向社会公众公开的个人信息；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">4</span><span style="font-family:&#39;宋体&#39;">、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">四、我们如何保护您的个人信息安全</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（一）为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如在您的浏览器与</span><span
                                        style="font-family:Calibri">“</span><span style="font-family:&#39;宋体&#39;">服务</span><span
                                        style="font-family:Calibri">”</span><span style="font-family:&#39;宋体&#39;">之间交换数据（如信用卡信息）时采用 </span><span
                                        style="font-family:Calibri">SSL </span><span style="font-family:&#39;宋体&#39;">加密保护技术；同时对网站本身提供 </span><span
                                        style="font-family:Calibri">https </span><span style="font-family:&#39;宋体&#39;">安全浏览方式；使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（二）我们的安全系统，有专门的团队在负责，公司从人力财力上均有较大的投入，且我们的系统安全性已通过权威第三方的监测，并获得了</span><span
                                        style="font-family:Calibri">ISO27001</span><span style="font-family:&#39;宋体&#39;">等认证。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（四）互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">五、您如何行使您的个人信息权利</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">您对您的个人信息您享有以下权利：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（一）您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">账户信息</span><span style="font-family:Calibri">——</span><span
                                        style="font-family:&#39;宋体&#39;">如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问北京首山网页执行此类操作。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">搜索信息</span><span style="font-family:Calibri">——</span><span
                                        style="font-family:&#39;宋体&#39;">您可以在北京首山网及相关网站中访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">如果您无法通过上述链接访问这些个人信息，您可以随时联系我们的客服。我们将在</span><span
                                        style="font-family:Calibri">30</span><span style="font-family:&#39;宋体&#39;">天内回复您的访问请求。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（二）您有权更正您的个人信息；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（三）您有权删除您的个人信息，您可以登录北京首山账号，点击您需要注销账号，再您确认删除您的账号信息时，我们将删除您的信息，由此产生的您的权益或者资产同时消失的损失由您承担；</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">（四）您有权改变您授权同意的范围。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">以上信息您均可以通过北京首山网站主页进行访问和更新。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">如果您认为北京首山存在违反法律的规定收集、使用您个人信息的情形，您可以通过北京首山客服与我们取得联系。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">六、您的个人信息如何在全球范围转移</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">1</span><span style="font-family:&#39;宋体&#39;">、法律法规有明确规定；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">2</span><span style="font-family:&#39;宋体&#39;">、获得您的明确授权；</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">、您通过互联网进行跨境交易等个人主动行为。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">七、我们如何处理儿童的个人信息</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">本政策所指的儿童是指不满</span><span style="font-family:Calibri">14</span><span
                                        style="font-family:&#39;宋体&#39;">周岁的个人，如果没有父母或监护人的同意，儿童不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">八、本隐私政策如何更新</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们提供的服务和产品会不时更新和调整，隐私政策也将随之可能变更，如发生变更我们会以公示的方式进行通知到您，也不排除使用</span><span
                                        style="font-family:Calibri">“</span><span style="font-family:&#39;宋体&#39;">弹框</span><span
                                        style="font-family:Calibri">”</span><span style="font-family:&#39;宋体&#39;">或者邮</span><span
                                        style="font-family:&#39;宋体&#39;">件、短信等方式通知到您，请您及时查看最新的隐私政策。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">九、如何联系我们</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">我们设立了个人信息保护部门，如对本政策内容有任何疑问、意见或建议，或发现您的个人信息可能被泄露，您可以发邮件到</span><span
                                        style="font-family:Calibri">services</span><span style="font-family:Calibri">@</span><span
                                        style="font-family:Calibri">shanshuibj</span><span style="font-family:Calibri">.com</span><span
                                        style="font-family:&#39;宋体&#39;">与我们联系，我们将在</span><span style="font-family:Calibri">15</span><span
                                        style="font-family:&#39;宋体&#39;">天内回复您的请求</span><span style="font-family:&#39;宋体&#39;">。</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过被告住所地有管辖权的法院提起诉讼来寻求解决方案。</span>
                                    </p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:Calibri">&nbsp;</span></p>
                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"><span
                                        style="font-family:&#39;宋体&#39;">最新更新日期</span><span style="font-family:Calibri">20</span><span
                                        style="font-family:Calibri">21</span><span style="font-family:&#39;宋体&#39;">年</span><span
                                        style="font-family:Calibri">3</span><span style="font-family:&#39;宋体&#39;">月</span><span
                                        style="font-family:Calibri">26</span><span style="font-family:&#39;宋体&#39;">日</span></p></div>
                            </div>
                        </van-tab>
                    </van-tabs>
                    <div class="check-box">
                        <van-checkbox v-model="agree2" shape="square" checked-color="#3a6ae4">我同意此条款</van-checkbox>
                    </div>
                    <div class="button-sunbmit">
                        <van-button @click.stop="becomePartnerSubmit2" type="primary" color="#3a6ae4" class="button-sunbmit-btn" size="normal" :disabled="!agree2">提交</van-button>
                    </div>
                </div>
                <div class="close" @click="show2 = false">X</div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'CardDetails',
        data () {
            return {
                card: {},
                tabIndex2: 'userRole',
                agree2: false,
                show2: false,
                active: 0
            };
        },
        mounted () {
            const card = this.$route.query.card;
            if (card) {
                this.$axios.get(
                    this.$requestUrl.product.getCardDetail,
                    {
                        params: {
                            cardId: card
                        }
                    }
                ).then(res => {
                    this.card = res.data;
                });
            }
        },
        computed: {
            ...mapState('user', ['userInfo']),
        },
        methods: {
            // 去办卡
            goHref () {
                if (this.userInfo.reward === 0 && this.userInfo.isAgree !== 1) {
                    this.show2 = true;
                    return;
                }
                window.location.href = this.card.pageUrl;
            },
            // 提交申请表单
            becomePartnerSubmit2 () {
                this.$axios.get(this.$requestUrl.product.userAgree).then(async res => {
                    if (res.code === '101') {
                        this.$toast('已同意条款');
                        this.$store.commit('user/changeUserAgree', 1); // 修改type
                    } else {
                        this.$toast(res.message);
                    }
                }).finally(() => {
                    this.show2 = false;
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.card-img{
    @include flexCenter;
    padding: 40px 0;
    div{
        width: 68%;
        img{
            width: 100%;
            display: block;
        }
        .name{
            text-align: center;
            width: 100%;
            display: block;
            color: #666666;
            padding-top: 20px;
            font-size: $button_font_size;
        }
    }
}
.tabs-box{
    margin-bottom: 160px;
    .desc{
        padding: 20px 40px 0;
        ::v-deep ul, li{
            list-style-type: disc!important;
        }
        ::v-deep ul{
            padding-top: 20px;
            padding-left: 40px;
        }
    }
}
.button-box{
    position: fixed;
    bottom: 0;
    left: 12%;
    right: 12%;
    padding: 40px 0;
    .apply-button{
        font-weight: bold;
        border-radius: 2em;
        background-color: rgb(164, 64, 222);
        border: none;
        height: 74px;
    }
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .content {
        padding: 0 20px;
        width: 80vw;
        background: white;
        height: 900px;

        .modal-rich-txt {
            margin-top: 20px;
            overflow-y: scroll;
            height: 520px;
        }
        .check-box{
            margin-top: 10px;
            padding: 20px;
        }
        .button-sunbmit{
            @include flexCenter;
            .button-sunbmit-btn{
                height: 68px;
                width: 180px;
            }
        }
    }
    .close{
        margin-top: 24px;
        color: #fff;
        height: 48px;
        width: 48px;
        @include flexCenter;
        border-radius: 50%;
        border: 1px solid #fff;
        font-size: 34px;
    }
    .poster{
        width: 80%;
        img{
            width: 100%;
        }
    }
}
</style>
