<template>
    <div class="list">
        <table>
            <tr>
                <th>申请人</th>
                <th>申请时间</th>
                <th>手机号</th>
                <th>推荐进度</th>
            </tr>
            <tr v-for="item in list" :key="item.id" class="tr">
                <td>{{item.name}}</td>
                <td>{{item.createTime}}</td>
                <td>{{item.mobile}}</td>
                <td>
                    <span class="status">{{item.state}}</span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'RecommendRecord',
        data () {
            return {
                list: []
            };
        },
        mounted () {
            this.$axios.get(this.$requestUrl.product.getSuperiorRecords).then(res => {
                this.list = res.data;
            });
        }
    };
</script>

<style scoped lang="scss">
.list{
    padding: 20px 30px;
    table{
        width: 100%;
        background-color: #fff;
        border-radius: 16px;
        tr{
            text-align: center;
            border-collapse: collapse;
            border-bottom: 1px solid #c7c7c7;
            th{
                line-height: 74px;
                font-size: 28px;
            }
        }
        .tr{
            td{
                line-height: 74px;
                font-size: 26px;
            }
            //text-align: center;
            .status{
                color: #fd9d22;
            }
        }
    }
}
</style>
