<template>
<div class="coupon-details">
    <h2>核销码</h2>
    <img :src="img" alt="">
</div>
</template>

<script>
    export default {
        name: 'CouponDetials',
        data () {
            return {
                img: ''
            };
        },
        created () {
            this.$toast.loading({
                message: '加载中'
            });
            const id = this.$route.params.id;
            this.$axios.get(this.$requestUrl.product.getCounponCode, {
                params: {
                    userCouponId: id
                }
            }).then(res => {
                this.img = res.data;
            }).finally(() => {
                this.$toast.clear();
            });
        }
    };
</script>

<style scoped lang="scss">
.coupon-details{
    margin: 120px 20px;
    background-color: white;
    border-radius: 16px;
    padding: 80px 40px;
    h2{
        margin-top: 0;
        text-align: center;
        margin-bottom: 48px;
    }
    img{
        display: block;
        margin: 0 auto;
    }
}
</style>
