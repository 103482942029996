import Vue from 'vue';
import VueRouter from 'vue-router';
import ProductDetails from '@/pages/product/Product_details';
import NotFound from '@/pages/NotFound';
import store from '@/store';
import Login from '../pages/login/Login';
import config from '../common/config';
import WechatLogin from '../pages/login/WechatLogin';
import Home from '@/pages/home/Home';
import Helper from '@/pages/helper/Helper';
import ProductCenter from '@/pages/product/ProductCenter';
import PersonalCenter from '@/pages/personal/PersonalCenter';
import CardDetails from '../pages/card/CardDetails';
import ProfitList from '../pages/home/ProfitList';
import CreateAddress from '../pages/address/CreateAddress';
import AddressList from '../pages/address/AddressList';
import Identity from '../pages/identity/Identity';
import WithdrawHome from '../pages/withdraw/WithdrawHome';
import CardList from '../pages/withdraw/CardList';
import AddWithdraw from '../pages/withdraw/AddWithdraw';
import WithdrawPage from '../pages/withdraw/WithdrawPage';
import ExchangeRecord from '../pages/product/ExchangeRecord';
import RecommendRecord from '../pages/personal/RecommendRecord';
import ChannelInfo from '../pages/personal/ChannelInfo';
import CouponList from '../pages/product/CouponList';
import CouponDetials from '../pages/product/CouponDetials';
import CouponRecord from '../pages/product/CouponRecord';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { titleName: '首卡', leftNavType: 0 }
    },
    {
        path: '/product_details',
        name: 'ProductDetails',
        component: ProductDetails,
        meta: { titleName: '商品详情', showTabbar: false }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { titleName: '登录', showNav: false, showTabbar: false }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { titleName: '微信快捷登录', showNav: false, showTabbar: false }
    },
    {
        path: '/helper',
        name: 'Helper',
        component: Helper,
        meta: { titleName: '帮助中心', leftNavType: 0 }
    },
    {
        path: '/product_center',
        name: 'ProductCenter',
        component: ProductCenter,
        meta: { titleName: '福利商城', leftNavType: 0 }
    },
    {
        path: '/personal_center',
        name: 'PersonalCenter',
        component: PersonalCenter,
        meta: { titleName: '个人中心', leftNavType: 0 }
    },
    {
        path: '/card_details',
        name: 'CardDetails',
        component: CardDetails,
        meta: { titleName: '卡片详情', showTabbar: false }
    },
    {
        path: '/profit_list',
        name: 'ProfitList',
        component: ProfitList,
        meta: { titleName: '收益明细', showTabbar: false }
    },
    {
        path: '/create_address',
        name: 'CreateAddress',
        component: CreateAddress,
        meta: { titleName: '添加收货地址', showTabbar: false }
    },
    {
        path: '/address_list',
        name: 'AddressList',
        component: AddressList,
        meta: { titleName: '收货地址列表', showTabbar: false }
    },
    {
        path: '/identity',
        name: 'Identity',
        component: Identity,
        meta: { titleName: '身份认证', showTabbar: false }
    },
    {
        path: '/withdraw_home',
        name: 'WithdrawHome',
        component: WithdrawHome,
        meta: { titleName: '提现中心', showTabbar: false }
    },
    {
        path: '/card_list',
        name: 'CardList',
        component: CardList,
        meta: { titleName: '卡片列表', showTabbar: false }
    },
    {
        path: '/add_withdraw',
        name: 'AddWithdraw',
        component: AddWithdraw,
        meta: { titleName: '添加提现方式', showTabbar: false }
    },
    {
        path: '/withdraw_page',
        name: 'WithdrawPage',
        component: WithdrawPage,
        meta: { titleName: '积分提现', showTabbar: false }
    },
    {
        path: '/exchange_record',
        name: 'ExchangeRecord',
        component: ExchangeRecord,
        meta: { titleName: '兑换记录', showTabbar: false }
    },
    {
        path: '/recommend_record',
        name: 'RecommendRecord',
        component: RecommendRecord,
        meta: { titleName: '推荐记录', showTabbar: false }
    },
    {
        path: '/channel_info',
        name: 'ChannelInfo',
        component: ChannelInfo,
        meta: { titleName: '渠道信息', showTabbar: false }
    },
    {
        path: '/coupon_list',
        name: 'CouponList',
        component: CouponList,
        meta: { titleName: '优惠券列表', showTabbar: false }
    },
    {
        path: '/coupon_detials/:id',
        name: 'CouponDetials',
        component: CouponDetials,
        meta: { titleName: '优惠券详情', showTabbar: false }
    },
    {
        path: '/coupon_record',
        name: 'CouponRecord',
        component: CouponRecord,
        meta: { titleName: '首刷礼申请信息', showTabbar: false }
    },
    {
        path: '*',
        component: NotFound
    } // 全不匹配的情况下，返回404，路由按顺序从上到下，依次匹配。最后一个*能匹配全部，
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    store.commit('setTitleName', { titleName: to.meta.titleName || config.defaultTitle });
    store.commit('setShowNav', { showNav: (to.meta.showNav === undefined || to.meta.showNav === null) ? config.defaultShowNav : to.meta.showNav });
    store.commit('setlLeftNavType', { leftNavType: (to.meta.leftNavType === undefined || to.meta.leftNavType === null) ? config.defaultLeftNavType : to.meta.leftNavType });
    store.commit('setShowTabbar', { showTabbar: (to.meta.showTabbar === undefined || to.meta.showTabbar === null) ? config.defaultShowTabbar : to.meta.showTabbar });

    if (to.name !== 'Login' && !store.state.user.userInfo.token) {
        next({
            path: '/login'
        });
        return;
    } else if (to.name !== 'Login' && to.name !== 'WechatLogin' && !store.state.user.userInfo.openid && store.state.user.userInfo.token) {
        next({
            path: '/login'
        });
        return;
    }
    // if (to.matched.some(record => record.meta.requiresAuth)) {
    //   // this route requires auth, check if logged in
    //   // if not, redirect to login page.
    //   if (!auth.loggedIn()) {
    //     next({
    //       path: '/login',
    //       query: { redirect: to.fullPath }
    //     });
    //   } else {
    //     next();
    //   }
    // } else {
    //   next(); // 确保一定要调用 next()
    // }
    next(); // 确保一定要调用 next()
});

router.afterEach((to, from) => {});

export default router;
