// 获取所有模块
const modules = require.context('./request_module', false, /\.js$/);
const requestUrl = {};
// 拼接请求地址
modules.keys().forEach(key => {
    const exportObj = modules(key).default;
    const modelName = key.match(/^\.\/(.*)\.js$/)[1];
    requestUrl[modelName] = {};
    for (const modelKey in exportObj) {
        if (Object.prototype.hasOwnProperty.call(exportObj, modelKey)) {
            requestUrl[modelName][modelKey] = exportObj[modelKey];
        }
    }
});
export {
    requestUrl
};
