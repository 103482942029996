import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from '../api/axios';
import { requestUrl } from '../api/api';
import ipConfig from '../api/ip_config';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    // reducer: state => {
    //     console.log(state, 'state2');
    //     return state;
    // }
    modules: ['system', 'user']
});

Vue.use(Vuex);

// 获取所有模块
const modulesFile = require.context('./modules', false, /\.js$/);
const modules = {};
// 拼接请求地址
modulesFile.keys().forEach(key => {
    const currModule = modulesFile(key).default;
    if (!currModule.moduleName) {
        throw new Error('没有传入模块名称');
    }
    if (!currModule.module) {
        throw new Error('没有传入模块');
    }
    modules[currModule.moduleName] = currModule.module;
});

export default new Vuex.Store({
    state: {
        shagePartnerBill: '',
        shageProgramBill: '',
        titleName: '商城',
        showNav: true,
        showTabbar: true,
        orderType: 1, // 微信立减金类型
        leftNavType: 1 // 导航栏左侧的类型 1.显示back组件 2.显示地图
    },
    mutations: {
        // 设置标题
        setTitleName (state, { titleName }) {
            state.titleName = titleName;
        },
        // 设置微信立减金类型
        setOrderType (state, payload) {
            state.orderType = payload;
        },
        // 设置是否显示导航栏
        setShowNav (state, { showNav }) {
            state.showNav = showNav;
        },
        // 设置导航栏类型
        setlLeftNavType (state, { leftNavType }) {
            state.leftNavType = leftNavType;
        },
        // 设置tab栏
        setShowTabbar (state, { showTabbar }) {
            state.showTabbar = showTabbar;
        },
        // 设置合伙人海报
        setShagePartnerBill (state, shagePartnerBill) {
            state.shagePartnerBill = shagePartnerBill;
        },
        // 设置分享赚佣金海报
        setShageProgramBill (state, shageProgramBill) {
            state.shageProgramBill = shageProgramBill;
        }
    },
    getters: {
        getAppTitle: state => state.titleName,
        getShowNav: state => state.showNav,
        getShowTabbar: state => state.showTabbar,
        getShagePartnerBill: state => state.shagePartnerBill,
        getShageProgramBill: state => state.shageProgramBill,
        getLeftNavType: state => state.leftNavType
    },
    actions: {
        getPreLoad ({ commit, rootGetters }) {
            // 合伙人海报
            axios.get(requestUrl.home.getOrderType).then(res => {
                commit('setOrderType', res.data);
            });
        },
        getPoster ({ commit, rootGetters }) {
            // 合伙人海报
            axios.get(requestUrl.home.shagePartnerBill, {
                params: {
                    type: 2
                }
            }).then(res => {
                commit('setShagePartnerBill', res.data);
            });
            // 分享赚佣金海报
            axios.get(requestUrl.home.shageProgramBill, {
                params: {
                    type: 2
                }
            }).then(res => {
                commit('setShageProgramBill', res.data);
            });

            // 获取签名信息
            axios.post(requestUrl.home.getWechatConfig, {
                url: window.location.href.split('#')[0]
            }).then(res => {
                wx.config({
                    debug: ipConfig.dev, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    ...res.data,
                    openTagList: ['wx-open-launch-weapp'], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
                    jsApiList: ['updateAppMessageShareData', 'scanQRCode'] // 必填，需要使用的JS接口列表
                });
                console.log('zoule');
                wx.ready(function () { // 需在用户可能点击分享按钮前就先调用
                    wx.updateAppMessageShareData({
                        title: '光大信用卡', // 分享标题
                        desc: '光大信用卡', // 分享描述
                        link: `${ipConfig.shareUrl}${rootGetters['user/getUserId'] ? '?superior=' + rootGetters['user/getUserId'] : ''}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://shou.zopsen.com/share.jpg', // 分享图标
                        success: function () {
                            // 设置成功
                            console.log('设置分享成功');
                        }
                    });
                });
            });
        }
    },
    modules,
    plugins: [vuexLocal.plugin]
});
