<template>
    <div class="withdraw-page">
        <van-form @submit="onSubmit">
            <van-field name="scores" label="积分">
                <template #input>
                    <van-stepper v-model="formData.scores" input-width="120" integer :show-plus="false"
                                 :show-minus="false"/>
                    <van-button type="primary" size="mini" color="#3a6ae4FF" style="margin-left: 6px;"
                                native-type="button" @click="withdrawAll">全部提现
                    </van-button>
                </template>
            </van-field>
            <van-field
                v-model="expact"
                name="expact"
                readonly
                label="预计到帐金额"
                placeholder="预计到帐金额"
            />
            <div class="red tip">提现需要缴纳{{ free }}%的个人所得税,该税款由河南芸柏科技有限公司代缴。</div>
            <van-radio-group v-model="formData.card">
                <van-cell-group>
                    <van-cell :title="'卡号: ' + item.payeeAcc" clickable @click="formData.card = item.id" v-for="item in list" :key="item.id">
                        <template #right-icon>
                            <van-radio :name="item.id" />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" color="#3a6ae4FF">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    export default {
        name: 'WithdrawPage',
        data () {
            return {
                formData: {
                    scores: 0,
                    card: null
                },
                list: [],
                free: 0,
                money: {}
            };
        },
        methods: {
            onSubmit (v) {
                if (parseFloat(this.expact) < 10) {
                    this.$toast('最少提现10积分');
                    return;
                }
                this.$axios.post(`${this.$requestUrl.home.userDrawCash}?cash=${v.scores}&type=0&userBankId=${this.formData.card}`).then(res => {
                    if (res.code === '101') {
                        this.$toast('提现成功');
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 1000);
                    } else {
                        this.$toast(res.message);
                    }
                });
            },
            withdrawAll () {
                this.formData.scores = this.money.remainAmount;
            }
        },
        mounted () {
            this.$axios.get(
                this.$requestUrl.product.getUserTaxRatio
            ).then(res => {
                this.free = res.data;
            });
            this.$axios.get(this.$requestUrl.home.queryUserAccountList).then(res => {
                this.list = res.data;
                if (res.data.length > 0) {
                    this.formData.card = res.data[0].id;
                }
            });
            this.$axios.get(this.$requestUrl.home.getUserAccount).then(res => {
                this.money = res.data;
            });
        },
        computed: {
            expact: {
                get () {
                    if (!this.formData.scores) {
                        return 0;
                    }
                    return this.formData.scores - (this.formData.scores * (this.free / 100));
                },
                set (v) {
                    console.log(v);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
.withdraw-page {
    .tip {
        background-color: #fff;
        padding: 20px 30px;
    }
    ::v-deep .van-radio__icon--checked .van-icon.van-icon-success{
        background-color: $main_color;
        border: 1px solid $main_color;
    }
}
</style>
